import { styled } from 'lib/theme'
import { animated } from '@react-spring/web'

export const Box = styled(animated.div, {
  variants: {
    full: {
      true: {
        width: '100%',
        height: '100%',
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    fullHeight: {
      true: {
        height: '100%',
      },
    },
  },
})
