import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '..'

export const BadgeWETH: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='220'
    height='220'
    viewBox='0 0 220 220'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='M110 220C170.751 220 220 170.751 220 110C220 49.2487 170.751 0 110 0C49.2487 0 0 49.2487 0 110C0 170.751 49.2487 220 110 220Z'
      fill='#627EEA'
    />
    <SvgPath
      d='M113.424 27.5V88.4813L164.966 111.512L113.424 27.5Z'
      fill='white'
      fillOpacity='0.602'
    />
    <SvgPath
      d='M113.424 27.5L61.875 111.512L113.424 88.4813V27.5Z'
      fill='white'
    />
    <SvgPath
      d='M113.424 151.031V192.467L165 121.111L113.424 151.031Z'
      fill='white'
      fillOpacity='0.602'
    />
    <SvgPath
      d='M113.424 192.467V151.024L61.875 121.111L113.424 192.467Z'
      fill='white'
    />
    <SvgPath
      d='M113.424 141.44L164.966 111.513L113.424 88.4956V141.44Z'
      fill='white'
      fillOpacity='0.2'
    />
    <SvgPath
      d='M61.875 111.513L113.424 141.44V88.4956L61.875 111.513Z'
      fill='white'
      fillOpacity='0.602'
    />
  </Svg>
)
