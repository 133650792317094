import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Discord: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='22px'
    height='15px'
    viewBox='0 0 20 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='M16.375 1.1875C16.375 1.1875 16.375 1.1875 16.3438 1.1875C15.1875 0.65625 13.9062 0.25 12.625 0.03125C12.5938 0.03125 12.5938 0 12.5938 0L12.5625 0.03125C12.375 0.34375 12.2188 0.6875 12.0938 1C11.4062 0.90625 10.6875 0.84375 9.96875 0.84375C9.25 0.84375 8.5625 0.90625 7.875 1C7.75 0.6875 7.59375 0.34375 7.40625 0.03125H7.375C7.34375 0.03125 7.34375 0.03125 7.34375 0.03125C6.0625 0.25 4.78125 0.65625 3.625 1.1875C3.59375 1.1875 3.59375 1.1875 3.59375 1.1875C1.21875 4.75 0.5625 8.21875 0.875 11.6562C0.875 11.6562 0.875 11.6875 0.90625 11.6875C2.25 12.7188 3.8125 13.5 5.5 14C5.53125 14 5.53125 14 5.5625 14C5.90625 13.5 6.21875 13 6.5 12.4688V12.4375C6.5 12.4062 6.46875 12.4062 6.46875 12.375C5.96875 12.1875 5.46875 11.9688 5.03125 11.6875C5 11.6875 5 11.6875 5 11.6562C5 11.625 5 11.625 5.03125 11.5938C5.125 11.5312 5.21875 11.4688 5.3125 11.375C5.3125 11.375 5.3125 11.375 5.34375 11.375C5.34375 11.375 5.34375 11.375 5.375 11.375C8.375 12.75 11.625 12.75 14.5938 11.375H14.625H14.6562C14.75 11.4688 14.8438 11.5312 14.9375 11.5938C14.9688 11.625 14.9688 11.625 14.9688 11.6562C14.9688 11.6875 14.9688 11.6875 14.9375 11.6875C14.4688 11.9688 14 12.1875 13.5 12.375C13.4688 12.4062 13.4688 12.4062 13.4688 12.4375V12.4688C13.75 13 14.0625 13.5 14.4062 14C14.4062 14 14.4375 14 14.4688 14C16.125 13.5 17.6875 12.7188 19.0625 11.6875C19.0938 11.6875 19.0938 11.6562 19.0938 11.6562C19.4688 7.6875 18.4375 4.25 16.375 1.1875ZM6.9375 9.5625C6.03125 9.5625 5.28125 8.71875 5.28125 7.71875C5.28125 6.6875 6.03125 5.875 6.9375 5.875C7.875 5.875 8.59375 6.6875 8.59375 7.71875C8.59375 8.71875 7.84375 9.5625 6.9375 9.5625ZM13.0312 9.5625C12.125 9.5625 11.4062 8.71875 11.4062 7.71875C11.4062 6.6875 12.125 5.875 13.0312 5.875C13.9688 5.875 14.7188 6.6875 14.6875 7.71875C14.6875 8.71875 13.9688 9.5625 13.0312 9.5625Z'
      css={{
        fill: theme.colors.gray900,
        transition: theme.transitions.iconColor,
      }}
      className='primary fill'
    />
  </Svg>
)
