import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Money: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='13.5244'
    height='24.3311'
    viewBox='0 0 22 22'
    css={css}
  >
    <SvgPath
      className='primary fill'
      d="M10.957 21.9141C16.9512 21.9141 21.9141 16.9404 21.9141 10.957C21.9141 4.96289 16.9404 0 10.9463 0C4.96289 0 0 4.96289 0 10.957C0 16.9404 4.97363 21.9141 10.957 21.9141ZM10.957 17.2842C10.6562 17.2842 10.4307 17.0693 10.4307 16.7578L10.4307 15.9629C8.94824 15.8232 7.71289 15.0068 7.40137 13.7285C7.36914 13.6211 7.34766 13.5029 7.34766 13.3848C7.34766 12.998 7.61621 12.7295 7.99219 12.7295C8.3252 12.7295 8.55078 12.9014 8.67969 13.2881C8.8623 14.0186 9.44238 14.5771 10.4307 14.7168L10.4307 11.4512L10.3555 11.4297C8.50781 10.9893 7.55176 10.1514 7.55176 8.74414C7.55176 7.22949 8.75488 6.17676 10.4307 5.99414L10.4307 5.23145C10.4307 4.91992 10.6562 4.70508 10.957 4.70508C11.2578 4.70508 11.4834 4.91992 11.4834 5.23145L11.4834 5.99414C12.9014 6.15527 14.04 6.98242 14.3301 8.1748C14.3516 8.29297 14.3838 8.41113 14.3838 8.51855C14.3838 8.90527 14.1045 9.17383 13.7285 9.17383C13.374 9.17383 13.1592 8.95898 13.0518 8.62598C12.8262 7.88477 12.2783 7.41211 11.4834 7.27246L11.4834 10.3555L11.6338 10.3877C13.5566 10.7314 14.5342 11.6445 14.5342 13.1484C14.5342 14.8242 13.1914 15.8232 11.4834 15.9844L11.4834 16.7578C11.4834 17.0693 11.2578 17.2842 10.957 17.2842ZM10.4307 10.0762L10.4307 7.27246C9.49609 7.43359 8.92676 8.00293 8.92676 8.66895C8.92676 9.32422 9.36719 9.76465 10.3555 10.0547ZM11.4834 11.709L11.4834 14.7168C12.6113 14.5879 13.1592 13.9971 13.1592 13.2236C13.1592 12.5254 12.7939 12.0635 11.7197 11.7734Z"
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
    />
  </Svg>
)
