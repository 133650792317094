import { theme } from 'lib/theme'
import { Svg, SvgGroup, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Speaker: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='20'
    height='20'
    viewBox='0 0 650 550'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgGroup>
      <SvgPath
        d='m229.17 198.33h-89.168c-6.1875 0-12.125 2.4609-16.5 6.8359s-6.832 10.309-6.832 16.5v128.33c0 6.1875 2.457 12.125 6.832 16.5s10.312 6.832 16.5 6.832h89.543l128.33 93.332 27.125 0.003907v-373.34h-27.5zm-89.168 23.336h81.668v128.33h-81.668zm221.67 218.91-116.67-84.852v-140.2l116.67-95.457zm221.66-160.58c0.046875 60.52-22.352 118.9-62.871 163.86l-17.336-15.598c36.617-40.703 56.879-93.512 56.879-148.26s-20.262-107.56-56.879-148.26l17.336-15.598c40.52 44.957 62.918 103.34 62.871 163.86zm-58.332 0c0.023438 45.668-16.715 89.754-47.039 123.9l-17.5-15.492c26.516-29.883 41.16-68.453 41.16-108.41s-14.645-78.523-41.16-108.41l17.5-15.492c30.324 34.145 47.062 78.23 47.039 123.9zm-58.332 0c0 30.789-11.066 60.551-31.188 83.859l-17.664-15.258c16.457-19.07 25.508-43.414 25.508-68.602s-9.0508-49.531-25.508-68.602l17.664-15.262v0.003906c20.121 23.309 31.188 53.07 31.188 83.859z'
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
      />
    </SvgGroup>
  </Svg>
)
