import { theme } from 'lib/theme'
import { Svg, SvgGroup, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Reports: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='36px'
    height='28px'
    viewBox='0 0 650 650'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgGroup>
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray900,
          transition: theme.transitions.iconColor,
        }}
        d='m512.84 136.5-68.895-82.602c-9.9648-11.988-24.75-18.918-40.34-18.898h-176.1c-13.926 0-27.277 5.5312-37.125 15.375-9.8438 9.8477-15.375 23.199-15.375 37.125v385c0 13.926 5.5312 27.277 15.375 37.125 9.8477 9.8438 23.199 15.375 37.125 15.375h245c13.926 0 27.277-5.5312 37.125-15.375 9.8438-9.8477 15.375-23.199 15.375-37.125v-302.33c0.003906-12.301-4.2969-24.215-12.164-33.672zm-42.699 3.5h-50.137v-60.164zm19.863 332.5c0 4.6406-1.8438 9.0938-5.125 12.375s-7.7344 5.125-12.375 5.125h-245c-4.6406 0-9.0938-1.8438-12.375-5.125s-5.125-7.7344-5.125-12.375v-385c0-4.6406 1.8438-9.0938 5.125-12.375s7.7344-5.125 12.375-5.125h157.5v70c0 9.2812 3.6875 18.184 10.25 24.75 6.5664 6.5625 15.469 10.25 24.75 10.25h70z'
      />
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray900,
          transition: theme.transitions.iconColor,
        }}
        d='m245 332.5c0 27.848 11.062 54.555 30.754 74.246s46.398 30.754 74.246 30.754 54.555-11.062 74.246-30.754 30.754-46.398 30.754-74.246-11.062-54.555-30.754-74.246-46.398-30.754-74.246-30.754c-27.84 0.027344-54.531 11.098-74.215 30.785-19.688 19.684-30.758 46.375-30.785 74.215zm105 70c-22.883 0.027344-44.332-11.152-57.41-29.93-13.082-18.781-16.133-42.773-8.1758-64.23 7.9609-21.453 25.922-37.652 48.086-43.355v50.016c0 9.2812 3.6875 18.184 10.25 24.75 6.5664 6.5625 15.469 10.25 24.75 10.25h50.016c-3.8672 14.984-12.594 28.262-24.809 37.762-12.215 9.4961-27.234 14.68-42.707 14.738zm67.516-87.5h-50.016v-50.016c12.027 3.1445 23 9.4375 31.789 18.227 8.7891 8.7891 15.082 19.762 18.227 31.789z'
      />
    </SvgGroup>
  </Svg>
)
