import { styled, theme } from 'lib/theme'
import { Svg, SvgGroup, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

const InfoSvgPath = styled(SvgPath, {
  fill: theme.colors.gray800,
  transition: theme.transitions.iconColor,
})

export const Info: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    version='1.1'
    viewBox='0 0 600 600'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    css={{ css }}
  >
    <SvgPath
      className='primary fill'
      fill='$gray700'
      d='m392.79 112.3c-7.4648-2-15.422-0.95313-22.117 2.9102-6.6914 3.8672-11.578 10.234-13.578 17.699-2 7.4688-0.95312 15.422 2.9102 22.117 3.8672 6.6953 10.23 11.578 17.699 13.582 7.4648 2 15.422 0.95312 22.117-2.9141 6.6953-3.8633 11.578-10.23 13.578-17.695 2-7.4688 0.95312-15.426-2.9102-22.117-3.8672-6.6953-10.23-11.582-17.699-13.582z'
    />
    <SvgPath
      className='primary fill'
      fill='$gray700'
      d='m406.64 386.86c-24.984 11.07-63.375 22.902-53.305-14.691l45.762-170.78-99.379 6.8594h0.003906c-5.3945 0.36719-10.184 3.5859-12.566 8.4414-2.3828 4.8516-1.9961 10.609 1.0156 15.102 3.0078 4.4922 8.1836 7.043 13.578 6.6875l7.2734-0.47266c5.8867-0.38672 11.598 2.0898 15.336 6.6523 3.7422 4.5586 5.0547 10.645 3.5273 16.34l-40.801 152.28c-7.7656 28.977 25.77 47.973 69.977 25.602 20.609-11.742 39.203-26.715 55.07-44.348 1.5547-1.6758 1.7109-4.2148 0.38281-6.0742-1.332-1.8594-3.7852-2.5234-5.875-1.5938z'
    />
    <SvgPath
      className='primary fill'
      fill='$gray700'
      d='m350 28c-66.836 0-130.93 26.551-178.19 73.809-47.258 47.258-73.809 111.36-73.809 178.19s26.551 130.93 73.809 178.19c47.258 47.258 111.36 73.809 178.19 73.809s130.93-26.551 178.19-73.809c47.258-47.258 73.809-111.36 73.809-178.19 0-44.234-11.645-87.691-33.762-126-22.117-38.309-53.93-70.121-92.238-92.238-38.309-22.117-81.766-33.762-126-33.762zm0 476c-59.41 0-116.38-23.602-158.39-65.609s-65.609-98.98-65.609-158.39 23.602-116.38 65.609-158.39 98.98-65.609 158.39-65.609 116.38 23.602 158.39 65.609 65.609 98.98 65.609 158.39c-0.066406 59.387-23.688 116.32-65.684 158.32-41.992 41.996-98.93 65.617-158.32 65.684z'
    />
  </Svg>
)
