import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '..'

export const BadgeWBTC: React.FC<SvgIconProps> = ({ css }) => {
  return (
    <Svg
      width='84'
      height='84'
      viewBox='0 0 84 84'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      css={css}
    >
      <SvgPath
        d='M84 42.0017C84 65.1933 65.1944 83.9966 41.9899 84C18.8022 84 -0.00335874 65.1933 1.80101e-06 41.9983C-0.00671927 18.8033 18.7988 0 41.9966 0C65.1944 0 84 18.8067 84 42.0017Z'
        fill='#EAB300'
      />
      <SvgPath
        d='M58.5239 31.7062C57.9828 26.0816 53.1269 24.1966 46.9905 23.659V15.8538H42.2421V23.4507C40.9953 23.4507 39.7183 23.4742 38.4514 23.5011V15.8538H33.703L33.6996 23.6523C32.6713 23.6725 31.6598 23.6926 30.6751 23.6926V23.6691L24.1254 23.6658V28.7394C24.1254 28.7394 27.6338 28.6722 27.5733 28.736C29.4989 28.736 30.124 29.8515 30.3055 30.8158V39.7064C30.4399 39.7064 30.6113 39.7131 30.8062 39.74H30.3055L30.3021 52.1955C30.2181 52.8003 29.8619 53.7646 28.5177 53.768C28.5781 53.8218 25.0664 53.768 25.0664 53.768L24.1221 59.4397H30.3055C31.4548 59.4397 32.5873 59.4598 33.6962 59.4666L33.6996 67.3592H38.4447V59.5506C39.7452 59.5774 41.0054 59.5875 42.2387 59.5875L42.2354 67.3592H46.9838V59.4834C54.9684 59.0264 60.5637 57.0138 61.256 49.5142C61.8172 43.4763 58.9776 40.7782 54.4442 39.6896C57.2032 38.2918 58.9272 35.8222 58.5239 31.7062ZM51.8767 48.5802C51.8767 54.477 41.7783 53.8083 38.5556 53.8083V43.3486C41.7783 43.3554 51.8767 42.4314 51.8767 48.5802ZM49.6655 33.8264C49.6655 39.1923 41.2373 38.564 38.5556 38.5674V29.0854C41.2407 29.0854 49.6689 28.232 49.6655 33.8264Z'
        fill='white'
      />
      <SvgPath d='M30.312 39.498H31.0177V40.0188H30.312V39.498Z' fill='white' />
    </Svg>
  )
}
