import { theme } from 'lib/theme'
import { Svg, SvgPath, SvgRect } from 'primitives/Svg'
import { SvgIconProps } from '..'

export const BadgePolygon: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <g clipPath='url(#clip0_1995_2675)'>
      <SvgPath
        d='M18.125 7.30733C17.6875 7.02076 17.125 7.02076 16.625 7.30733L13.125 9.67151L10.75 11.176L7.3125 13.5402C6.875 13.8267 6.3125 13.8267 5.8125 13.5402L3.125 11.6775C2.6875 11.3909 2.375 10.8178 2.375 10.173V6.59091C2.375 6.01778 2.625 5.44464 3.125 5.08643L5.8125 3.29539C6.25 3.00882 6.8125 3.00882 7.3125 3.29539L10 5.15807C10.4375 5.44464 10.75 6.01778 10.75 6.66255V9.02673L13.125 7.45061V5.01479C13.125 4.44166 12.875 3.86852 12.375 3.51031L7.375 0.143148C6.9375 -0.143419 6.375 -0.143419 5.875 0.143148L0.75 3.58195C0.25 3.86852 0 4.44166 0 5.01479V11.7491C0 12.3223 0.25 12.8954 0.75 13.2536L5.8125 16.6208C6.25 16.9073 6.8125 16.9073 7.3125 16.6208L10.75 14.3282L13.125 12.7521L16.5625 10.4596C17 10.173 17.5625 10.173 18.0625 10.4596L20.75 12.2506C21.1875 12.5372 21.5 13.1103 21.5 13.7551V17.3372C21.5 17.9103 21.25 18.4834 20.75 18.8417L18.125 20.6327C17.6875 20.9193 17.125 20.9193 16.625 20.6327L13.9375 18.8417C13.5 18.5551 13.1875 17.982 13.1875 17.3372V15.0446L10.8125 16.6208V18.9849C10.8125 19.5581 11.0625 20.1312 11.5625 20.4894L16.625 23.8566C17.0625 24.1431 17.625 24.1431 18.125 23.8566L23.1875 20.4894C23.625 20.2029 23.9375 19.6297 23.9375 18.9849V12.179C23.9375 11.6058 23.6875 11.0327 23.1875 10.6745L18.125 7.30733Z'
        className='primary fill'
        css={{
          fill: theme.colors.voilet100,
        }}
      />
    </g>
    <defs>
      <clipPath id='clip0_1995_2675'>
        <SvgRect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </Svg>
)
