import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '..'

export const BadgeUSDT: React.FC<SvgIconProps> = ({ css }) => {
  return (
    <Svg
      width='220'
      height='220'
      viewBox='0 0 220 220'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      css={css}
    >
      <g clipPath='url(#clip0_108_407)'>
        <SvgPath
          d='M110 220C170.751 220 220 170.751 220 110C220 49.2487 170.751 0 110 0C49.2487 0 0 49.2487 0 110C0 170.751 49.2487 220 110 220Z'
          fill='#26A17B'
        />
        <SvgPath
          fillRule='evenodd'
          clipRule='evenodd'
          d='M123.214 119.51V119.497C122.458 119.552 118.56 119.785 109.863 119.785C102.919 119.785 98.0311 119.579 96.3124 119.497V119.517C69.5824 118.342 49.6311 113.687 49.6311 108.118C49.6311 102.557 69.5824 97.9022 96.3124 96.7059V114.883C98.0586 115.007 103.064 115.303 109.98 115.303C118.278 115.303 122.437 114.959 123.214 114.89V96.7197C149.889 97.9091 169.792 102.563 169.792 108.118C169.792 113.687 149.889 118.328 123.214 119.51V119.51ZM123.214 94.8291V78.5628H160.435V53.7578H59.0911V78.5628H96.3124V94.8222C66.0624 96.2109 43.313 102.206 43.313 109.383C43.313 116.561 66.0624 122.549 96.3124 123.945V176.071H123.214V123.931C153.416 122.542 176.11 116.554 176.11 109.383C176.11 102.213 153.416 96.2247 123.214 94.8291'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_108_407'>
          <rect width='220' height='220' fill='white' />
        </clipPath>
      </defs>
    </Svg>
  )
}
