import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '..'

export const BadgeArbitrum: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='40'
    height='44'
    viewBox='0 0 40 44'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='M24.5536 20.1606L27.7849 14.6775L36.4947 28.2431L36.4988 30.8465L36.4704 12.9317C36.4498 12.4937 36.2172 12.093 35.8463 11.8566L20.1654 2.8371C19.7988 2.65678 19.3334 2.65895 18.9674 2.84278C18.9179 2.86769 18.8716 2.8945 18.8272 2.92401L18.7725 2.95839L3.55158 11.7786L3.49256 11.8057C3.41648 11.8404 3.33986 11.8851 3.26757 11.9365C2.97896 12.1433 2.78728 12.4495 2.72528 12.7926C2.71607 12.8446 2.70903 12.8976 2.70605 12.9512L2.72988 27.55L10.8427 14.9759C11.8639 13.3086 14.0894 12.7712 16.1555 12.8007L18.58 12.8646L4.29369 35.7757L5.97771 36.7452L20.4351 12.8879L26.8254 12.8646L12.4054 37.324L18.4145 40.7806L19.1326 41.1932C19.4363 41.3167 19.7942 41.3229 20.1005 41.2122L36.0017 31.9972L32.9615 33.7589L24.5536 20.1606ZM25.7863 37.9172L19.7171 28.3912L23.4222 22.104L31.3931 34.6678L25.7863 37.9172Z'
      fill='#2D374B'
    />
    <SvgPath
      d='M19.7153 28.3915L25.7848 37.9173L31.3914 34.6681L23.4204 22.1042L19.7153 28.3915Z'
      fill='#28A0F0'
    />
    <SvgPath
      d='M36.4979 30.8467L36.4938 28.2433L27.784 14.6777L24.5527 20.1608L32.9609 33.7591L36.0011 31.9974C36.2992 31.7554 36.4798 31.3999 36.4987 31.0165L36.4979 30.8467Z'
      fill='#28A0F0'
    />
    <SvgPath
      d='M0 33.3018L4.29316 35.7756L18.5794 12.8646L16.1549 12.8007C14.0892 12.7714 11.8637 13.3086 10.8422 14.9758L2.72935 27.5499L0 31.7434V33.3018V33.3018Z'
      fill='white'
    />
    <SvgPath
      d='M26.8248 12.8646L20.4344 12.8879L5.97705 36.7452L11.0305 39.6546L12.4045 37.324L26.8248 12.8646Z'
      fill='white'
    />
    <SvgPath
      d='M39.191 12.8316C39.1377 11.4952 38.414 10.272 37.2807 9.55965L21.3943 0.423712C20.2732 -0.140786 18.8726 -0.141598 17.7493 0.423442C17.6167 0.490315 2.30023 9.37338 2.30023 9.37338C2.08824 9.47491 1.8841 9.59593 1.69214 9.73347C0.680376 10.4588 0.0636245 11.5856 0 12.8235V31.7438L2.72935 27.5502L2.70553 12.9512C2.70878 12.8979 2.71555 12.8451 2.72475 12.7934C2.78648 12.4498 2.97844 12.1436 3.26705 11.9365C3.33934 11.8851 18.917 2.86771 18.9666 2.8428C19.3329 2.65896 19.798 2.6568 20.1646 2.83711L35.8455 11.8566C36.2164 12.093 36.449 12.4937 36.4695 12.9317V31.016C36.4506 31.3994 36.299 31.7549 36.0009 31.9969L32.9607 33.7586L31.392 34.6678L25.7855 37.917L20.0996 41.2119C19.7934 41.3226 19.4352 41.3164 19.1317 41.1929L12.4046 37.3238L11.0303 39.6543L17.076 43.135C17.2758 43.2487 17.4539 43.3494 17.6001 43.4317C17.8265 43.5587 17.9808 43.6434 18.0352 43.67C18.4649 43.8787 19.083 44 19.6402 44C20.1508 44 20.649 43.9063 21.1203 43.7217L37.6354 34.1577C38.5832 33.4232 39.141 32.3156 39.191 31.1159V12.8316Z'
      fill='#96BEDC'
    />
  </Svg>
)
