import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Home: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='M9.57786 0.322181C9.14831 -0.107394 8.45181 -0.107394 8.02222 0.322181L0.322181 8.02222C-0.107394 8.45181 -0.107394 9.14831 0.322181 9.57786C0.751766 10.0074 1.44825 10.0074 1.87783 9.57786L2.20001 9.25567V16.5001C2.20001 17.1076 2.69251 17.6001 3.30002 17.6001H5.50003C6.10754 17.6001 6.60004 17.1076 6.60004 16.5001V14.3001C6.60004 13.6925 7.09253 13.2001 7.70004 13.2001H9.90006C10.5076 13.2001 11.0001 13.6925 11.0001 14.3001V16.5001C11.0001 17.1076 11.4925 17.6001 12.1001 17.6001H14.3001C14.9076 17.6001 15.4001 17.1076 15.4001 16.5001V9.25567L15.7223 9.57786C16.1518 10.0074 16.8484 10.0074 17.2779 9.57786C17.7075 9.14831 17.7075 8.45181 17.2779 8.02222L9.57786 0.322181Z'
      className='primary fill'
      css={{
        fill: theme.colors.gray900,
        transition: theme.transitions.iconColor,
      }}
    />
  </Svg>
)
