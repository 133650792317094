import { Svg, SvgPath } from 'primitives/Svg'
import { theme } from 'lib/theme'
import { SvgIconProps } from '.'

export const Subscriptions: React.FC<SvgIconProps> = ({ css }) => {
  return (
    <Svg
      width='40px'
      height='44px'
      viewBox='0 0 752 752'
      xmlns='http://www.w3.org/2000/svg'
      css={css}
    >
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
        d='m500.08 195.09h-141.6c-28.414 0-52.094 20.836-56.355 47.832-19.891 3.3164-36.465 16.574-44.043 35.047-35.52 2.8398-63.461 32.203-63.461 68.668v141.6c0 37.887 30.781 68.668 68.668 68.668h141.6c35.992 0 65.828-27.941 68.668-63.461 18.469-7.5781 31.73-24.152 35.047-44.043 26.992-4.2617 47.832-27.941 47.832-56.355v-141.13c0.47266-31.254-25.102-56.828-56.359-56.828zm33.152 198.43c0 15.156-9.9453 27.469-23.68 31.73v-125.97c0-31.258-25.574-56.828-56.828-56.828h-125.97c4.2617-13.734 16.574-23.68 31.73-23.68h141.6c18.469 0 33.152 14.68 33.152 33.152zm-82.875 94.715c0 24.625-20.363 44.988-44.988 44.988l-141.61 0.003907c-24.625 0-44.988-20.363-44.988-44.988v-141.6c0-24.625 20.363-44.988 44.988-44.988h141.6c24.625 0 44.988 20.363 44.988 44.988z'
      />
    </Svg>
  )
}
