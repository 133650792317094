import * as React from 'react'
import { SVGProps } from 'react'

export const ChatWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.45 4H5.55A2.55 2.55 0 0 0 3 6.55V19.3a.85.85 0 0 0 1.284.731L8.1 17.719a.85.85 0 0 1 .468-.119h8.882A2.55 2.55 0 0 0 20 15.05v-8.5A2.55 2.55 0 0 0 17.45 4ZM8.1 11.65a.85.85 0 1 1 0-1.7.85.85 0 0 1 0 1.7Zm3.4 0a.85.85 0 1 1 0-1.7.85.85 0 0 1 0 1.7Zm3.4 0a.85.85 0 1 1 0-1.7.85.85 0 0 1 0 1.7Z"
      fill="#fff"
    />
  </svg>
)
