import { theme } from 'lib/theme'
import { Svg, SvgGroup, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Binance: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='24'
    height='24'
    viewBox='0 0 126.61 126.61'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgGroup>
      <SvgPath
        d='m38.73 53.2 24.59-24.58 24.6 24.6 14.3-14.31-38.9-38.91-38.9 38.9z'
        className='primary fill'
        css={{
          fill: theme.colors.gray900,
        }}
      />
      <SvgPath
        d='m0 63.31 14.3-14.31 14.31 14.31-14.31 14.3z'
        className='primary fill'
        css={{
          fill: theme.colors.gray900,
        }}
      />
      <SvgPath
        d='m38.73 73.41 24.59 24.59 24.6-24.6 14.31 14.29-38.9 38.91-38.91-38.88z'
        className='primary fill'
        css={{
          fill: theme.colors.gray900,
        }}
      />
      <SvgPath
        d='m98 63.31 14.3-14.31 14.31 14.3-14.31 14.32z'
        className='primary fill'
        css={{
          fill: theme.colors.gray900,
        }}
      />
      <SvgPath
        d='m77.83 63.3-14.51-14.52-10.73 10.73-1.24 1.23-2.54 2.54 14.51 14.5 14.51-14.47z'
        className='primary fill'
        css={{
          fill: theme.colors.gray900,
        }}
      />
    </SvgGroup>
  </Svg>
)
