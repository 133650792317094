import { Svg, SvgGroup, SvgPath, SvgRect } from 'primitives/Svg'
import { SvgIconProps } from '..'

export const BadgeBUSD: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='25'
    height='25'
    viewBox='0 0 642 642'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <circle cx='321' cy='321' r='321' fill='black' />
    <SvgGroup>
      <SvgPath
        d='M320.994 116.81L368.365 165.303L249.082 284.539L201.711 237.187L320.994 116.81Z'
        fill='#F0B90B'
        stroke='#F0B90B'
      />
      <SvgPath
        d='M392.907 188.694L440.277 237.187L249.082 428.306L201.711 380.954L392.907 188.694Z'
        fill='#F0B90B'
        stroke='#F0B90B'
      />
      <SvgPath
        d='M177.17 260.578L224.541 309.07L177.17 356.422L129.799 309.07L177.17 260.578Z'
        fill='#F0B90B'
        stroke='#F0B90B'
      />
      <SvgPath
        d='M464.819 260.578L512.19 309.07L320.994 500.19L273.624 452.838L464.819 260.578Z'
        fill='#F0B90B'
        stroke='#F0B90B'
      />
    </SvgGroup>
  </Svg>
)
