import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const EyeClosed: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='18'
    height='18'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.96579 1.83429C2.65336 1.52187 2.14683 1.52187 1.83441 1.83429C1.52199 2.14671 1.52199 2.65324 1.83441 2.96566L13.0344 14.1657C13.3468 14.4781 13.8534 14.4781 14.1658 14.1657C14.4782 13.8533 14.4782 13.3467 14.1658 13.0343L12.9872 11.8557C14.2173 10.8739 15.1513 9.53686 15.634 7.99994C14.6146 4.75428 11.5823 2.39998 8.00026 2.39998C6.70142 2.39998 5.47485 2.70951 4.39033 3.25884L2.96579 1.83429ZM6.3746 5.2431L7.58571 6.45422C7.71794 6.41884 7.85691 6.39998 8.00034 6.39998C8.88394 6.39998 9.60034 7.11632 9.60034 7.99998C9.60034 8.14334 9.58146 8.28238 9.5461 8.41454L10.7571 9.62566C11.0387 9.14926 11.2003 8.5935 11.2003 7.99998C11.2003 6.23266 9.76762 4.79998 8.00034 4.79998C7.4068 4.79998 6.85103 4.96155 6.3746 5.2431Z'
      className='primary fill'
      css={{
        fill: theme.colors.voilet100,
      }}
    />
    <SvgPath
      d='M9.96308 13.3574L7.79952 11.1938C6.19191 11.0945 4.90556 9.80807 4.80619 8.20047L1.86774 5.26202C1.20031 6.05778 0.684899 6.98534 0.366211 7.99999C1.38563 11.2457 4.41786 13.6 7.99997 13.6C8.67733 13.6 9.33492 13.5158 9.96308 13.3574Z'
      className='primary fill'
      css={{
        fill: theme.colors.voilet100,
      }}
    />
  </Svg>
)
