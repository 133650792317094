import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const ClearList: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='25'
    height='25'
    viewBox='0 0 700 600'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='m460.32 156.24 53.199-53.199-53.199-53.199c-4.4805-4.4805-4.4805-11.199 0-15.68 4.4805-4.4805 11.199-4.4805 15.68 0l53.199 53.199 53.199-53.199c4.4805-4.4805 11.199-4.4805 15.68 0 4.4805 4.4805 4.4805 11.199 0 15.68l-53.199 53.199 53.199 53.199c4.4805 4.4805 4.4805 11.199 0 15.68-2.2383 2.2383-5.0391 3.3594-7.8398 3.3594s-5.6016-1.1211-7.8398-3.3594l-53.199-52.637-53.199 53.199c-2.2383 2.2383-5.0391 3.3594-7.8398 3.3594-2.8008 0-5.6016-1.1211-7.8398-3.3594-4.4805-4.4805-4.4805-11.762 0-16.242zm-351.12-44.238h324.8c6.1602 0 11.199-5.0391 11.199-11.199 0-6.1602-5.0391-11.199-11.199-11.199h-324.8c-6.1602 0-11.199 5.0391-11.199 11.199 0 6.1602 5.0391 11.199 11.199 11.199zm481.6 397.6h-481.6c-6.1602 0-11.199 5.0391-11.199 11.199 0 6.1602 5.0391 11.199 11.199 11.199h481.6c6.1602 0 11.199-5.0391 11.199-11.199 0-6.1602-5.0391-11.199-11.199-11.199zm-481.6-257.6h481.6c6.1602 0 11.199-5.0391 11.199-11.199 0-6.1602-5.0391-11.199-11.199-11.199h-481.6c-6.1602 0-11.199 5.0391-11.199 11.199 0 6.1602 5.0391 11.199 11.199 11.199zm481.6 117.6h-481.6c-6.1602 0-11.199 5.0391-11.199 11.199 0 6.1602 5.0391 11.199 11.199 11.199h481.6c6.1602 0 11.199-5.0391 11.199-11.199 0-6.1602-5.0391-11.199-11.199-11.199z'
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
    />
  </Svg>
)
