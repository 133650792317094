import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const VideoCamera: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='24'
    height='24'
    viewBox='0 0 700 550'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='m560 146.16c-11.199-6.1602-24.078-6.1602-34.719 0.55859l-73.359 43.121v-8.3984c0-23.52-19.039-42.559-42.559-42.559l-244.72-0.003906c-23.52 0-42.559 19.039-42.559 42.559v196.56c0 23.52 19.039 42.559 42.559 42.559h245.28c23.52 0 42.559-19.039 42.559-42.559v-10.078l73.922 43.121c5.6016 3.3594 11.762 5.0391 17.359 5.0391 6.1602 0 11.762-1.6797 17.359-4.4805 11.199-6.1602 17.359-17.359 17.359-30.238l-0.55859-204.96c0-12.32-6.7227-23.52-17.922-30.238zm-129.92 231.84c0 11.199-8.9609 20.16-20.16 20.16h-245.28c-11.199 0-20.16-8.9609-20.16-20.16v-196c0-11.199 8.9609-20.16 20.16-20.16h245.28c11.199 0 20.16 8.9609 20.16 20.16zm119.84 14c-1.6797 1.1211-6.7188 3.3594-12.32 0l-85.121-49.84v-126l84.559-50.398c5.6016-3.3594 10.641-1.1211 12.32 0 1.6797 1.1211 6.1602 4.4805 6.1602 10.641l0.55859 204.96c0 6.7148-4.4766 9.5156-6.1562 10.637z'
      fill='white'
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
    />
  </Svg>
)
