import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '..'

export const BadgeSUSHI: React.FC<SvgIconProps> = ({ css }) => {
  return (
    <Svg
      width='220'
      height='220'
      viewBox='0 0 220 220'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      css={css}
    >
      <g clipPath='url(#clip0_304_30)'>
        <circle
          cx='110'
          cy='110'
          r='110'
          fill={theme.colors.gray700.toString()}
        />
        <SvgPath
          d='M64.1104 46.7144L185.01 129.264L155.11 173.464L34.2104 90.2644L64.1104 46.7144Z'
          fill='url(#paint0_linear_304_30)'
        />
        <SvgPath
          d='M185.013 129.267C174.613 144.217 139.513 138.367 106.363 114.967C72.5627 92.2166 54.3627 61.6666 64.1127 46.7166C74.5127 31.7666 109.613 37.6166 142.763 61.0166C176.563 83.1166 194.763 114.317 185.013 129.267Z'
          fill='url(#paint1_linear_304_30)'
        />
        <SvgPath
          d='M155.114 172.815C144.714 187.765 109.614 181.915 76.4644 158.515C43.3144 135.115 24.4644 105.215 34.8644 89.6145C45.2644 74.6645 80.3644 80.5145 113.514 103.915C146.664 127.315 164.864 157.865 155.114 172.815Z'
          fill='url(#paint2_linear_304_30)'
        />
        <SvgPath
          d='M185.017 129.267L155.117 173.467C144.717 188.417 109.617 181.917 76.4668 159.167C69.9668 154.617 64.1168 150.067 58.2668 144.867C62.8168 144.217 68.6668 141.617 74.5168 135.117C84.9168 124.067 90.1168 121.467 94.6668 122.117C99.2168 122.117 104.417 126.667 112.867 137.717C121.317 148.767 133.017 152.017 140.167 146.167C140.817 145.517 141.467 145.517 142.117 144.867C147.967 140.317 149.917 138.367 160.967 117.567C163.567 112.367 172.667 103.917 185.017 107.817C188.267 116.267 188.267 123.417 185.017 129.267Z'
          fill='#0E0F23'
        />
        <SvgPath
          fillRule='evenodd'
          clipRule='evenodd'
          d='M180.462 126.665C171.362 139.665 139.512 133.165 108.962 111.715C77.7624 90.2653 59.5624 62.9653 68.6624 49.9653C77.7624 36.9653 109.612 43.4653 140.162 64.9153C170.712 86.3653 188.912 113.665 180.462 126.665ZM151.862 107.165C147.312 113.665 131.712 110.415 116.112 100.015C101.162 89.6153 92.0624 75.9653 96.6124 69.4653C101.162 62.9653 116.762 66.2153 132.362 76.6153C147.312 87.0153 156.412 100.665 151.862 107.165Z'
          fill='white'
        />
        <SvgPath
          d='M61.5148 61.6678C61.5148 61.0178 60.8648 60.3678 60.2148 61.0178C59.5648 61.6678 58.9148 61.6678 58.9148 62.3178C59.5648 64.2678 60.2148 65.5678 60.2148 66.8678C60.2148 67.5178 60.8648 68.1678 61.5148 67.5178C62.1648 67.5178 62.8148 66.8678 62.1648 66.2178C62.1648 64.9178 62.1648 63.6178 61.5148 61.6678Z'
          fill='white'
        />
        <SvgPath
          d='M64.7625 72.0652C64.7625 71.4152 64.1125 70.7652 63.4625 71.4152C62.8125 72.0652 62.8125 72.0652 62.8125 72.7152C69.9625 88.9652 84.9125 106.515 104.413 119.515C105.063 120.165 105.713 119.515 106.363 119.515C107.013 118.865 106.363 118.215 106.363 117.565C86.2125 104.565 71.9125 87.6652 64.7625 72.0652Z'
          fill='white'
        />
        <SvgPath
          d='M143.419 135.765C142.769 135.765 142.119 135.765 142.119 136.415C142.119 137.065 142.119 137.715 142.769 137.715C144.719 138.365 147.319 139.015 149.269 139.665C149.919 139.665 150.569 139.665 150.569 139.015C150.569 138.365 150.569 137.715 149.919 137.715C147.969 137.065 145.369 136.415 143.419 135.765Z'
          fill='white'
        />
        <SvgPath
          d='M155.113 138.368C154.463 138.368 153.812 139.018 153.812 139.668C153.812 140.318 154.463 140.968 155.113 140.968C160.313 141.618 166.163 142.268 170.713 141.618C171.363 141.618 172.013 140.968 172.013 140.318C172.013 139.668 171.363 139.018 170.713 139.018C165.513 139.668 160.313 139.018 155.113 138.368Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_304_30'
          x1='87.7977'
          y1='34.9773'
          x2='111.774'
          y2='117.308'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#03B8FF' />
          <stop offset='1' stopColor='#FA52A0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_304_30'
          x1='107.544'
          y1='29.229'
          x2='131.521'
          y2='111.56'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#03B8FF' />
          <stop offset='1' stopColor='#FA52A0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_304_30'
          x1='68.1184'
          y1='40.7092'
          x2='92.0949'
          y2='123.04'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#03B8FF' />
          <stop offset='1' stopColor='#FA52A0' />
        </linearGradient>
        <clipPath id='clip0_304_30'>
          <rect width='220' height='220' fill='white' />
        </clipPath>
      </defs>
    </Svg>
  )
}
