import * as React from 'react'
import { SVGProps } from 'react'

export const BellWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 4a6 6 0 0 0-6 6v3.586l-.707.707A1 1 0 0 0 6 16h12a1 1 0 0 0 .707-1.707L18 13.586V10a6 6 0 0 0-6-6Zm0 16a3 3 0 0 1-3-3h6a3 3 0 0 1-3 3Z"
      fill="#fff"
    />
  </svg>
)
