import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const DoubleTick: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='28'
    height='28'
    viewBox='0 0 700 600'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='m339.92 446.32c-6.1602 0-11.762-2.8008-16.238-7.2812l-122.64-132.16c-8.9609-9.5195-8.3984-24.641 0-34.16 8.9609-9.5195 22.961-8.9609 31.922 0l106.4 114.8 235.76-267.12c8.3984-9.5195 22.961-10.078 31.922-1.1211 8.9609 8.9609 9.5195 24.078 1.1211 34.16l-251.44 285.6c-4.4844 4.4805-10.645 7.2812-16.801 7.2812z'
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
    />
    <SvgPath
      d='m343.84 315.28 140-159.04c7.2812-8.3984 8.9609-21.281 2.8008-30.801-8.3984-12.879-25.199-14-34.719-2.8008l-142.8 162.4c-7.8398 8.9609-7.8398 22.961 1.1211 31.359 9.5195 9.5234 24.637 8.9609 33.598-1.1172z'
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
    />
    <SvgPath
      d='m247.52 402.64-119.28-129.92c-7.2812-7.8398-17.922-10.641-28-7.2812-15.68 6.1602-19.039 24.078-8.9609 35.281l122.08 132.72c8.9609 10.078 24.641 10.078 33.602 0 8.9648-7.8359 8.9648-21.836 0.5625-30.797z'
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
    />
  </Svg>
)
