import * as React from 'react'
import { SVGProps } from 'react'

export const At = (props: SVGProps<SVGSVGElement>) => (
  <svg width={13} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.94 12.685c-1.014 0-1.91-.14-2.69-.418-.778-.275-1.433-.681-1.963-1.218-.53-.537-.931-1.195-1.203-1.974-.272-.779-.408-1.67-.408-2.674 0-.972.138-1.84.413-2.606a5.315 5.315 0 0 1 1.208-1.948A5.244 5.244 0 0 1 4.226.619C4.985.337 5.843.196 6.8.196c.931 0 1.747.152 2.446.457.703.302 1.29.711 1.76 1.228a5.145 5.145 0 0 1 1.064 1.735 5.67 5.67 0 0 1 .358 1.99c0 .48-.023.967-.07 1.46a5.542 5.542 0 0 1-.293 1.363c-.15.411-.38.742-.691.994-.308.252-.726.378-1.253.378-.232 0-.487-.036-.765-.11a2.01 2.01 0 0 1-.741-.362 1.008 1.008 0 0 1-.383-.681h-.06a1.766 1.766 0 0 1-1.009.96c-.262.102-.582.147-.96.133a2.592 2.592 0 0 1-1.138-.288 2.477 2.477 0 0 1-.825-.726 3.412 3.412 0 0 1-.497-1.069 5.069 5.069 0 0 1-.164-1.317c0-.448.066-.857.198-1.228.133-.371.317-.696.552-.975.239-.278.517-.5.835-.666a2.868 2.868 0 0 1 1.04-.313c.331-.033.633-.018.904.045.272.06.496.15.672.273.175.12.286.252.333.398h.06v-.557h1.053v4.495c0 .278.078.523.234.735.156.212.383.318.681.318.338 0 .597-.116.776-.348.182-.232.306-.59.373-1.073.07-.484.104-1.104.104-1.86a4.77 4.77 0 0 0-.184-1.312 4.334 4.334 0 0 0-.547-1.218 3.979 3.979 0 0 0-.92-1 4.246 4.246 0 0 0-1.307-.676c-.497-.165-1.062-.248-1.696-.248-.778 0-1.476.12-2.093.363a4.195 4.195 0 0 0-1.566 1.049 4.594 4.594 0 0 0-.979 1.67c-.222.653-.333 1.395-.333 2.227 0 .846.11 1.596.333 2.252.225.657.557 1.21.994 1.661.441.45.986.792 1.636 1.024.65.236 1.399.353 2.247.353.365 0 .724-.035 1.079-.104.355-.07.668-.146.94-.229.271-.083.467-.144.586-.184l.279.915a6.132 6.132 0 0 1-.806.258c-.328.087-.68.158-1.054.214a7.09 7.09 0 0 1-1.064.085Zm-.576-3.958c.444 0 .803-.09 1.078-.268.276-.18.476-.45.602-.81.126-.362.189-.818.189-1.368 0-.557-.07-.99-.209-1.302-.14-.312-.345-.53-.616-.657-.272-.125-.607-.188-1.005-.188-.377 0-.7.099-.969.298a1.886 1.886 0 0 0-.612.785c-.139.325-.208.68-.208 1.064 0 .424.056.824.169 1.198.112.372.296.673.552.905.255.229.598.343 1.029.343Z"
      fill="#898EA2"
    />
  </svg>
)
