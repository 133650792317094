import * as React from 'react'
import { SVGProps } from 'react'

const CheckboxDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} stroke="#fff" />
    <path d="m10.75 16.75 3 3 7.5-7.5" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export { CheckboxDisabled }
