import { Svg, SvgPath } from 'primitives/Svg'
import { theme } from 'lib/theme'
import { SvgIconProps } from '.'

export const Scheduled: React.FC<SvgIconProps> = ({ css }) => {
  return (
    <Svg
      width='30px'
      height='30px'
      viewBox='120 0 752 752'
      xmlns='http://www.w3.org/2000/svg'
      css={css}
    >
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
        d='m271.65 332.32h-48.191c-6.0156 0-10.898 4.8828-10.898 10.898s4.8828 10.898 10.898 10.898h48.191c6.0156 0 10.898-4.8828 10.898-10.898s-4.8828-10.898-10.898-10.898z'
      />
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
        d='m271.65 402.88h-48.191c-6.0156 0-10.898 4.8828-10.898 10.898s4.8828 10.898 10.898 10.898h48.191c6.0156 0 10.898-4.8828 10.898-10.898 0-6.0195-4.8828-10.898-10.898-10.898z'
      />
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
        d='m271.65 473.45h-48.191c-6.0156 0-10.898 4.8828-10.898 10.898 0 6.0156 4.8828 10.898 10.898 10.898h48.191c6.0156 0 10.898-4.8828 10.898-10.898 0-6.0156-4.8828-10.898-10.898-10.898z'
      />
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
        d='m380.48 332.32h-48.195c-6.0156 0-10.898 4.8828-10.898 10.898s4.8828 10.898 10.898 10.898h48.203c6.0156 0 10.898-4.8828 10.898-10.898s-4.8828-10.898-10.906-10.898z'
      />
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
        d='m380.48 402.88h-48.195c-6.0156 0-10.898 4.8828-10.898 10.898s4.8828 10.898 10.898 10.898h48.203c6.0156 0 10.898-4.8828 10.898-10.898 0-6.0195-4.8828-10.898-10.906-10.898z'
      />
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
        d='m380.48 473.45h-48.195c-6.0156 0-10.898 4.8828-10.898 10.898 0 6.0156 4.8828 10.898 10.898 10.898h48.203c6.0156 0 10.898-4.8828 10.898-10.898 0-6.0156-4.8828-10.898-10.906-10.898z'
      />
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
        d='m441.09 354.11h48.203c6.0156 0 10.898-4.8828 10.898-10.898s-4.8828-10.898-10.898-10.898h-48.203c-6.0156 0-10.898 4.8828-10.898 10.898 0.003907 6.0156 4.8828 10.898 10.898 10.898z'
      />
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
        d='m441.09 424.68h48.203c6.0156 0 10.898-4.8828 10.898-10.898s-4.8828-10.898-10.898-10.898h-48.203c-6.0156 0-10.898 4.8828-10.898 10.898 0.003907 6.0156 4.8828 10.898 10.898 10.898z'
      />
      <SvgPath
        className='primary fill'
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor,
        }}
        d='m596.85 488.54c-5.8867 1.25-9.6523 7.0352-8.4062 12.918 1.0469 4.9688 1.5781 10.102 1.5781 15.238 0 40.461-32.91 73.379-73.367 73.379-40.457 0-73.367-32.91-73.367-73.379 0-40.453 32.91-73.367 73.367-73.367 18.172 0 35.301 6.6367 48.746 18.652l-48.781 52.176-19.387-20.594c-4.125-4.3906-11.016-4.5938-15.398-0.46875-4.3828 4.1172-4.5938 11.016-0.46875 15.398l27.344 29.062c2.0625 2.1875 4.9297 3.4258 7.9375 3.4258h0.019531c3.0078 0 5.8867-1.25 7.9375-3.457l84.27-90.148c4.1055-4.3906 3.8711-11.293-0.53125-15.398-4.3906-4.1172-11.293-3.8828-15.398 0.51953l-12.688 13.578c-2.9648-2.6758-6.0352-5.1719-9.2578-7.4219v-171.91c0-0.050781-0.019532-0.10547-0.019532-0.15234v-81.953c0-6.0156-4.8828-10.898-10.898-10.898h-71.066v-19.961c0-6.0156-4.8828-10.898-10.898-10.898s-10.898 4.8828-10.898 10.898v19.965l-99.938-0.003906v-19.961c0-6.0156-4.8828-10.898-10.898-10.898s-10.898 4.8828-10.898 10.898v19.965l-99.938-0.003906v-19.961c0-6.0156-4.8828-10.898-10.898-10.898s-10.898 4.8828-10.898 10.898v19.965l-71.086-0.003906c-6.0156 0-10.898 4.8828-10.898 10.898v82.086 0.011719 0.011719 311.53c0 6.0156 4.8828 10.898 10.898 10.898h301.69c0.23438 0 0.45703-0.0625 0.69141-0.074219 16.617 14.16 38.117 22.77 61.605 22.77 52.48 0 95.16-42.691 95.16-95.168 0-6.6445-0.69141-13.285-2.0547-19.738-1.2383-5.8906-7.0273-9.6367-12.906-8.4219zm-433.29-293.01h60.191v19.395c0 6.0156 4.8828 10.898 10.898 10.898s10.898-4.8828 10.898-10.898v-19.395h99.945v19.395c0 6.0156 4.8828 10.898 10.898 10.898s10.898-4.8828 10.898-10.898v-19.395h99.938v19.395c0 6.0156 4.8828 10.898 10.898 10.898s10.898-4.8828 10.898-10.898v-19.395h60.18v60.297l-385.64-0.003907zm0 82.109h385.66v149.7c-10.316-3.7539-21.266-5.8008-32.566-5.8008-52.469 0-95.16 42.691-95.16 95.16 0 18.641 5.4609 35.98 14.758 50.68l-272.69-0.003906z'
      />
    </Svg>
  )
}
