import { IMAGE_DOMAIN, SITE_DOMAIN } from 'config'
import Nexthead from 'next/head'
import { useRouter } from 'next/router'

export interface SeoProps {
  metaTitle?: string
  metaDesc?: string
  metaImg?: string
  // All pages (including the canonical page) should contain a canonical tag to prevent any
  // possible duplication. https://www.deepcrawl.com/blog/best-practice/canonical-tags-easy-dos-donts
  // This is marked as optional as PaginationControls cmp sets the canonical on paginated pages.
  canonicalUrl?: string
  // Still expose the the full OG API as an object incase that's more intuitive for the implementor
  og?: {
    url?: string
    title?: string
    desc?: string
    image?: string
  }
}

export const Head = ({
  metaTitle,
  metaDesc,
  metaImg,
  canonicalUrl,
  og,
}: SeoProps) => {
  const { asPath } = useRouter() || {}
  return (
    <Nexthead>
      <title>{metaTitle}</title>
      <meta charSet='utf-8' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0, maximum-scale=1.0'
      />
      {canonicalUrl && <link rel='canonical' href={canonicalUrl} />}
      {metaDesc && <meta name='description' content={metaDesc} />}
      <meta
        property='og:url'
        content={og?.url || canonicalUrl || `${SITE_DOMAIN}${asPath}`}
      />
      <meta property='og:title' content={og?.title || metaTitle} />
      <meta property='twitter:title' content={og?.title || metaTitle} />
      <meta property='og:description' content={og?.desc || metaDesc} />
      <meta property='twitter:description' content={og?.desc || metaDesc} />
      {(og?.image || metaImg) && (
        <>
          <meta property='og:image' content={og?.image || metaImg} />
          <meta property='twitter:image' content={og?.image || metaImg} />
        </>
      )}
      <meta content='summary_large_image' name='twitter:card' />
      {/* Preconnect to CDN */}
      <link rel='preconnect' href={IMAGE_DOMAIN} />
      <link rel='dns-prefetch' href={IMAGE_DOMAIN} />
      <link
        rel='preload'
        href='/static/fonts/Inter/Inter-Regular.ttf'
        as='font'
        type='font/ttf'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        href='/static/fonts/Inter/Inter-Medium.ttf'
        as='font'
        type='font/ttf'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        href='/static/fonts/Inter/Inter-SemiBold.ttf'
        as='font'
        type='font/ttf'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        href='/static/fonts/Inter/Inter-Bold.ttf'
        as='font'
        type='font/ttf'
        crossOrigin='anonymous'
      />
    </Nexthead>
  )
}
