export const typography = {
  body: {
    paragraph: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 0.21
      // color: '$gray900',
      // fontWeight: 400
    },
    caption1: {
      fontSize: '0.833rem',
      lineHeight: '1.25rem',
      letterSpacing: 0.2
      // color: '$gray800',
      // fontWeight: 400
    }
  }
}
