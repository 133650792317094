import * as React from 'react'
import { SVGProps } from 'react'

export const ArrowBtnPurple = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} fill="#7230FF" />
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} stroke="#2C2E35" />
    <path
      d="M22.75 16H9.25m8.25-5.25L22.75 16l-5.25-5.25ZM22.75 16l-5.25 5.25L22.75 16Z"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
