import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '..'

export const BadgeMetaMask: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <g clipPath='url(#clip0_1995_5352)'>
      <SvgPath
        d='M22.7449 16.6147L21.2957 21.3268L18.4506 20.5786L22.7449 16.6147Z'
        fill='#E4761B'
        stroke='#E4761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M18.4506 20.5786L21.1947 16.9632L22.7449 16.6147L18.4506 20.5786Z'
        fill='#E4761B'
        stroke='#E4761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M20.6125 13.0623L22.7448 16.6149L21.1946 16.9633L20.6125 13.0623ZM20.6125 13.0623L21.9846 12.1484L22.7448 16.6149L20.6125 13.0623Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M18.8663 10.3778L22.8102 8.82422L22.6142 9.67524L18.8663 10.3778ZM22.4895 10.6976L18.8663 10.3778L22.6142 9.67524L22.4895 10.6976Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M22.4895 10.6976L21.9846 12.1484L18.8663 10.3778L22.4895 10.6976ZM23.2795 9.18405L22.6142 9.67524L22.8103 8.82422L23.2795 9.18405ZM22.4895 10.6976L22.6142 9.67524L23.1429 10.0979L22.4895 10.6976Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M15.3915 19.4307L16.3775 19.7334L18.4505 20.5787L15.3915 19.4307Z'
        fill='#E2761B'
        stroke='#E2761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M21.9844 12.1485L22.4893 10.6978L22.9229 11.0176L21.9844 12.1485ZM21.9844 12.1485L17.892 10.852L18.8661 10.3779L21.9844 12.1485Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M19.8402 5.92871L18.8661 10.378L17.892 10.8521L19.8402 5.92871Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M22.8102 8.82448L18.8663 10.378L19.8404 5.92871L22.8102 8.82448Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M19.8403 5.9286L23.9565 5.47168L22.8102 8.82437L19.8403 5.9286Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M21.9844 12.1486L20.6124 13.0624L17.892 10.8521L21.9844 12.1486Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M23.7783 2.26758L23.9565 5.47177L19.8403 5.92869L23.7783 2.26758Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M23.7783 2.26758L15.5519 8.01912L15.4449 4.11813L23.7783 2.26758Z'
        fill='#E2761B'
        stroke='#E2761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M9.32715 3.6499L15.445 4.11825L15.5519 8.01925L9.32715 3.6499Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M17.8922 10.8521L15.552 8.01914L19.8404 5.92871L17.8922 10.8521Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M17.8922 10.8521L20.6126 13.0624L16.7934 13.4679L17.8922 10.8521Z'
        fill='#E4761B'
        stroke='#E4761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M16.7934 13.4679L15.552 8.01904L17.8922 10.852L16.7934 13.4679Z'
        fill='#E4761B'
        stroke='#E4761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M19.8404 5.92869L15.552 8.01912L23.7784 2.26758L19.8404 5.92869Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M9.38057 20.4876L11.3228 22.4353L8.67969 20.1221L9.38057 20.4876Z'
        fill='#C0AD9E'
        stroke='#C0AD9E'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M18.4506 20.5788L19.3712 17.2147L21.1947 16.9634L18.4506 20.5788Z'
        fill='#CD6116'
        stroke='#CD6116'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M1.5047 11.1147L4.86654 7.71631L1.93829 10.7092L1.5047 11.1147Z'
        fill='#E2761B'
        stroke='#E2761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M21.1946 16.9636L19.3711 17.2149L20.6125 13.0626L21.1946 16.9636ZM15.5519 8.01925L12.2792 7.91644L9.32715 3.6499L15.5519 8.01925Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M20.6124 13.0625L19.3711 17.2148L19.2701 15.1872L20.6124 13.0625Z'
        fill='#E4751F'
        stroke='#E4751F'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M16.7934 13.468L20.6126 13.0625L19.2702 15.1872L16.7934 13.468Z'
        fill='#CD6116'
        stroke='#CD6116'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M12.2791 7.91602L15.5519 8.01882L16.7932 13.4677L12.2791 7.91602Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M12.2793 7.91576L3.44701 0.0566406L9.32726 3.64922L12.2793 7.91576ZM9.32132 21.6806L1.6473 23.9653L0.114868 18.3394L9.32132 21.6806Z'
        fill='#E4761B'
        stroke='#E4761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M2.6449 12.5196L5.54345 10.332L7.97276 10.8746L2.6449 12.5196Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M7.97302 10.8748L5.5437 10.3322L6.84448 5.16895L7.97302 10.8748Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M1.93829 10.709L5.54366 10.332L2.64511 12.5196L1.93829 10.709Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M19.2702 15.187L17.7734 14.4616L16.7934 13.4678L19.2702 15.187Z'
        fill='#CD6116'
        stroke='#CD6116'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M1.93839 10.7091L1.68298 9.4126L5.54376 10.3322L1.93839 10.7091Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M17.4645 16.0954L17.7733 14.4619L19.2701 15.1873L17.4645 16.0954Z'
        fill='#233447'
        stroke='#233447'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M19.3711 17.2146L17.4645 16.0951L19.2701 15.187L19.3711 17.2146Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M5.54349 10.3322L1.68272 9.41264L1.36792 8.32173L5.54349 10.3322ZM6.84428 5.16895L5.54349 10.3322L1.36792 8.32173L6.84428 5.16895ZM6.84428 5.16895L12.2791 7.91621L7.97281 10.8748L6.84428 5.16895Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M7.97302 10.8746L12.2793 7.91602L14.1978 13.5533L7.97302 10.8746ZM14.1978 13.5533L8.24625 13.4334L7.97302 10.8746L14.1978 13.5533Z'
        fill='#E4761B'
        stroke='#E4761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M2.6449 12.5195L7.97276 10.8746L8.24599 13.4334L2.6449 12.5195ZM16.7931 13.4677L14.1975 13.5533L12.279 7.91602L16.7931 13.4677Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M17.7734 14.4616L17.4646 16.0951L16.7934 13.4678L17.7734 14.4616Z'
        fill='#CD6116'
        stroke='#CD6116'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M3.4469 0.0566406L12.2792 7.91576L6.84438 5.16849L3.4469 0.0566406Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M0.114868 18.34L7.56319 18.043L9.32132 21.6812L0.114868 18.34Z'
        fill='#E4761B'
        stroke='#E4761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M9.32112 21.6812L7.56299 18.0429L11.2218 17.8716L9.32112 21.6812Z'
        fill='#CD6116'
        stroke='#CD6116'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M17.4646 16.095L19.3712 17.2144L20.4225 19.5962L17.4646 16.095ZM8.24625 13.4334L0.114868 18.3396L2.64516 12.5195L8.24625 13.4334ZM7.56319 18.0426L0.114868 18.3396L8.24625 13.4334L7.56319 18.0426ZM16.7934 13.4677L17.2805 15.1697L14.9284 15.3011L16.7934 13.4677ZM14.9284 15.3011L14.1978 13.5533L16.7934 13.4677L14.9284 15.3011Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M11.3228 22.4351L9.32117 21.6812L16.2824 22.8063L11.3228 22.4351Z'
        fill='#C0AD9E'
        stroke='#C0AD9E'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M2.64511 12.5196L1.5047 11.1145L1.93829 10.709L2.64511 12.5196Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M17.0724 21.9724L16.2824 22.8063L9.32117 21.6812L17.0724 21.9724Z'
        fill='#D7C1B3'
        stroke='#D7C1B3'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M17.4466 19.9277L9.32117 21.6812L11.2219 17.8716L17.4466 19.9277Z'
        fill='#E4761B'
        stroke='#E4761B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M9.32117 21.6812L17.4466 19.9277L17.0724 21.9725L9.32117 21.6812Z'
        fill='#D7C1B3'
        stroke='#D7C1B3'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M1.36787 8.32188L1.10059 3.92969L6.84422 5.1691L1.36787 8.32188ZM1.93808 10.7093L1.1481 9.93825L1.68267 9.41279L1.93808 10.7093Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M12.8672 14.6846L14.1977 13.5537L14.0076 16.3181L12.8672 14.6846Z'
        fill='#CD6116'
        stroke='#CD6116'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M14.1978 13.5537L12.8673 14.6846L10.931 15.6499L14.1978 13.5537Z'
        fill='#CD6116'
        stroke='#CD6116'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M20.4224 19.5964L20.0601 19.3394L17.4645 16.0952L20.4224 19.5964Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M10.9308 15.6497L8.24603 13.4336L14.1976 13.5535L10.9308 15.6497Z'
        fill='#CD6116'
        stroke='#CD6116'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M14.0075 16.3181L14.1976 13.5537L14.9282 15.3015L14.0075 16.3181Z'
        fill='#E4751F'
        stroke='#E4751F'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M0.886963 8.75586L1.36807 8.32178L1.68288 9.41269L0.886963 8.75586Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M14.0077 16.3181L10.931 15.6498L12.8673 14.6846L14.0077 16.3181Z'
        fill='#233447'
        stroke='#233447'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M6.84422 5.16849L1.10059 3.92908L3.44675 0.0566406L6.84422 5.16849Z'
        fill='#763D16'
        stroke='#763D16'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M16.2825 22.8063L16.6092 23.6631L11.3229 22.4351L16.2825 22.8063Z'
        fill='#C0AD9E'
        stroke='#C0AD9E'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M11.222 17.8717L10.931 15.6499L14.0077 16.3182L11.222 17.8717Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M8.24603 13.4336L10.9308 15.6497L11.2218 17.8715L8.24603 13.4336Z'
        fill='#E4751F'
        stroke='#E4751F'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M14.9282 15.3013L17.2803 15.1699L20.06 19.3394L14.9282 15.3013ZM8.24605 13.4336L11.2218 17.8715L7.56299 18.0428L8.24605 13.4336Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M14.9282 15.3013L17.8505 19.5792L14.0075 16.3179L14.9282 15.3013Z'
        fill='#E4751F'
        stroke='#E4751F'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M14.0075 16.3179L17.8505 19.5792L17.4466 19.9276L14.0075 16.3179Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M17.4466 19.9276L11.2218 17.8715L14.0075 16.3179L17.4466 19.9276ZM20.06 19.3393L17.8504 19.5792L14.9281 15.3013L20.06 19.3393Z'
        fill='#F6851B'
        stroke='#F6851B'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M20.6422 21.3496L19.6919 23.183L16.6092 23.6628L20.6422 21.3496ZM16.6092 23.6628L16.2825 22.8061L17.0725 21.9722L16.6092 23.6628Z'
        fill='#C0AD9E'
        stroke='#C0AD9E'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M17.0724 21.9722L17.6544 21.7494L16.6091 23.6628L17.0724 21.9722ZM16.6091 23.6628L17.6544 21.7494L20.6421 21.3496L16.6091 23.6628Z'
        fill='#C0AD9E'
        stroke='#C0AD9E'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M20.06 19.3394L20.7906 19.7677L18.5692 20.0247L20.06 19.3394Z'
        fill='#161616'
        stroke='#161616'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M18.5693 20.0247L17.8506 19.5792L20.0602 19.3394L18.5693 20.0247ZM18.3139 20.3617L21.0165 20.0476L20.6423 21.3498L18.3139 20.3617Z'
        fill='#161616'
        stroke='#161616'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M20.6422 21.3497L17.6546 21.7495L18.3139 20.3616L20.6422 21.3497ZM17.6546 21.7495L17.0725 21.9722L17.4467 19.9275L17.6546 21.7495ZM17.4467 19.9275L17.8506 19.5791L18.5693 20.0246L17.4467 19.9275ZM20.7907 19.7676L21.0164 20.0475L18.3139 20.3616L20.7907 19.7676Z'
        fill='#161616'
        stroke='#161616'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M18.3138 20.3616L18.5692 20.0246L20.7907 19.7676L18.3138 20.3616ZM17.4467 19.9275L18.3138 20.3616L17.6545 21.7495L17.4467 19.9275Z'
        fill='#161616'
        stroke='#161616'
        strokeWidth='0.237586'
      />
      <SvgPath
        d='M18.5693 20.0248L18.3138 20.3618L17.4467 19.9277L18.5693 20.0248Z'
        fill='#161616'
        stroke='#161616'
        strokeWidth='0.237586'
      />
    </g>
    <defs>
      <clipPath id='clip0_1995_5352'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </Svg>
)
