import * as React from 'react'
import { SVGProps } from 'react'

export const CrossWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={47} height={47} rx={23.5} fill="#282A30" />
    <rect x={0.5} y={0.5} width={47} height={47} rx={23.5} stroke="#2C2E35" />
    <path
      d="m19.5 19.5 9.084 9.084M28.5 19.5l-9 9"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
