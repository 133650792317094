import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Lock: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='18'
    height='17'
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      css={{
        fill: theme.colors.gray900,
        transition: theme.transitions.iconColor,
      }}
      className='primary fill'
      d='M8.00002 2.25317C6.16202 2.25317 4.66669 3.74851 4.66669 5.58651V7.58651H4.00002C3.26402 7.58651 2.66669 8.18384 2.66669 8.91984V14.2532C2.66669 14.9892 3.26402 15.5865 4.00002 15.5865H5.33335H7.33335H8.66669H10H12C12.736 15.5865 13.3334 14.9892 13.3334 14.2532V8.91984C13.3334 8.18384 12.736 7.58651 12 7.58651H11.3334V5.58651C11.3334 3.74851 9.83802 2.25317 8.00002 2.25317ZM6.00002 5.58651C6.00002 4.48384 6.89735 3.58651 8.00002 3.58651C9.10269 3.58651 10 4.48384 10 5.58651V7.58651H6.00002V5.58651ZM8.66669 12.7352V14.2532H7.33335V12.7352C6.84869 12.4538 6.55402 11.8832 6.70735 11.2525C6.82469 10.7725 7.22735 10.3845 7.71135 10.2838C8.57402 10.1025 9.33335 10.7552 9.33335 11.5865C9.33335 12.0792 9.06402 12.5045 8.66669 12.7352Z'
      fill='white'
    />
  </Svg>
)
