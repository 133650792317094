import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const PageError: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='28'
    height='28'
    viewBox='0 0 700 600'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='m589.88 181.98v378.02h-479.77v-560h308.54l4.9648 4.9648 161.3 164.61 4.9648 4.9648v7.4453zm-365.61 287.03c-11.582 14.062-33.086-3.3086-21.508-17.371 33.914-43.012 93.473-62.039 147.24-62.039 52.938 0 113.32 19.023 147.24 62.039 11.582 14.062-10.754 31.434-21.508 17.371-28.953-36.395-81.062-52.113-125.73-52.113s-96.781 15.715-125.73 52.113zm283.72-209.28-36.395-36.395-17.371 18.199-18.199-18.199-36.395 36.395 18.199 17.371-18.199 18.199 36.395 36.395 18.199-18.199 17.371 18.199 36.395-36.395-18.199-18.199zm-315.98 0 35.57-36.395 18.199 18.199 18.199-18.199 36.395 36.395-18.199 17.371 18.199 18.199-36.395 36.395-18.199-18.199-18.199 18.199-35.57-36.395 17.371-18.199zm236.57-199.35v103.4h101.74zm125.73 138.96h-160.47v-164.61h-248.98v490.52h409.45v-325.91z'
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
    />
  </Svg>
)
