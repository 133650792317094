import { ComponentProps } from '@stitches/react'
import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

const QrCodePath: React.FC<ComponentProps<typeof SvgPath>> = (props) => (
  <SvgPath
    className='primary fill'
    css={{
      fill: theme.colors.voilet100,
    }}
    {...props}
  />
)

export const QrCode: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='148'
    height='148'
    viewBox='0 0 148 148'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <QrCodePath d='M5.92 0H0V5.92H5.92V0Z' />
    <QrCodePath d='M11.84 0H5.92V5.92H11.84V0Z' />
    <QrCodePath d='M17.76 0H11.84V5.92H17.76V0Z' />
    <QrCodePath d='M23.68 0H17.76V5.92H23.68V0Z' />
    <QrCodePath d='M29.6 0H23.68V5.92H29.6V0Z' />
    <QrCodePath d='M35.52 0H29.6V5.92H35.52V0Z' />
    <QrCodePath d='M41.44 0H35.52V5.92H41.44V0Z' />
    <QrCodePath d='M53.28 0H47.36V5.92H53.28V0Z' />
    <QrCodePath d='M59.2 0H53.28V5.92H59.2V0Z' />
    <QrCodePath d='M71.04 0H65.12V5.92H71.04V0Z' />
    <QrCodePath d='M76.96 0H71.04V5.92H76.96V0Z' />
    <QrCodePath d='M88.8 0H82.88V5.92H88.8V0Z' />
    <QrCodePath d='M100.64 0H94.72V5.92H100.64V0Z' />
    <QrCodePath d='M112.48 0H106.56V5.92H112.48V0Z' />
    <QrCodePath d='M118.4 0H112.48V5.92H118.4V0Z' />
    <QrCodePath d='M124.32 0H118.4V5.92H124.32V0Z' />
    <QrCodePath d='M130.24 0H124.32V5.92H130.24V0Z' />
    <QrCodePath d='M136.16 0H130.24V5.92H136.16V0Z' />
    <QrCodePath d='M142.08 0H136.16V5.92H142.08V0Z' />
    <QrCodePath d='M148 0H142.08V5.92H148V0Z' />
    <QrCodePath d='M5.92 5.92H0V11.84H5.92V5.92Z' />
    <QrCodePath d='M41.44 5.92H35.52L35.52 11.84H41.44V5.92Z' />
    <QrCodePath d='M59.2 5.92H53.28V11.84H59.2V5.92Z' />
    <QrCodePath d='M76.96 5.92H71.04V11.84H76.96V5.92Z' />
    <QrCodePath d='M88.8 5.92H82.88V11.84H88.8V5.92Z' />
    <QrCodePath d='M100.64 5.92H94.72V11.84H100.64V5.92Z' />
    <QrCodePath d='M112.48 5.92H106.56V11.84H112.48V5.92Z' />
    <QrCodePath d='M148 5.92H142.08V11.84H148V5.92Z' />
    <QrCodePath d='M5.92 11.84H0V17.76H5.92V11.84Z' />
    <QrCodePath d='M17.76 11.84H11.84V17.76H17.76V11.84Z' />
    <QrCodePath d='M23.68 11.84H17.76V17.76H23.68V11.84Z' />
    <QrCodePath d='M29.6 11.84H23.68V17.76H29.6V11.84Z' />
    <QrCodePath d='M41.44 11.84H35.52V17.76H41.44V11.84Z' />
    <QrCodePath d='M53.28 11.84H47.36V17.76H53.28V11.84Z' />
    <QrCodePath d='M59.2 11.84H53.28V17.76H59.2V11.84Z' />
    <QrCodePath d='M65.12 11.84H59.2V17.76H65.12V11.84Z' />
    <QrCodePath d='M82.88 11.84H76.96V17.76H82.88V11.84Z' />
    <QrCodePath d='M100.64 11.84H94.72V17.76H100.64V11.84Z' />
    <QrCodePath d='M112.48 11.84H106.56V17.76H112.48V11.84Z' />
    <QrCodePath d='M124.32 11.84H118.4V17.76H124.32V11.84Z' />
    <QrCodePath d='M130.24 11.84H124.32V17.76H130.24V11.84Z' />
    <QrCodePath d='M136.16 11.84H130.24V17.76H136.16V11.84Z' />
    <QrCodePath d='M148 11.84H142.08V17.76H148V11.84Z' />
    <QrCodePath d='M5.92 17.76H0V23.68H5.92V17.76Z' />
    <QrCodePath d='M17.76 17.76H11.84V23.68H17.76V17.76Z' />
    <QrCodePath d='M23.68 17.76H17.76V23.68H23.68V17.76Z' />
    <QrCodePath d='M29.6 17.76H23.68V23.68H29.6V17.76Z' />
    <QrCodePath d='M41.44 17.76H35.52V23.68H41.44V17.76Z' />
    <QrCodePath d='M59.2 17.76H53.28V23.68H59.2V17.76Z' />
    <QrCodePath d='M82.88 17.76H76.96V23.68H82.88V17.76Z' />
    <QrCodePath d='M88.8 17.76H82.88V23.68H88.8V17.76Z' />
    <QrCodePath d='M94.72 17.76H88.8V23.68H94.72V17.76Z' />
    <QrCodePath d='M100.64 17.76H94.72V23.68H100.64V17.76Z' />
    <QrCodePath d='M112.48 17.76H106.56V23.68H112.48V17.76Z' />
    <QrCodePath d='M124.32 17.76H118.4V23.68H124.32V17.76Z' />
    <QrCodePath d='M130.24 17.76H124.32V23.68H130.24V17.76Z' />
    <QrCodePath d='M136.16 17.76H130.24V23.68H136.16V17.76Z' />
    <QrCodePath d='M148 17.76H142.08V23.68H148V17.76Z' />
    <QrCodePath d='M5.92 23.68H0V29.6H5.92V23.68Z' />
    <QrCodePath d='M17.76 23.68H11.84V29.6H17.76V23.68Z' />
    <QrCodePath d='M23.68 23.68H17.76V29.6H23.68V23.68Z' />
    <QrCodePath d='M29.6 23.68H23.68V29.6H29.6V23.68Z' />
    <QrCodePath d='M41.44 23.68H35.52V29.6H41.44V23.68Z' />
    <QrCodePath d='M71.04 23.68H65.12V29.6H71.04V23.68Z' />
    <QrCodePath d='M82.88 23.68H76.96V29.6H82.88V23.68Z' />
    <QrCodePath d='M94.72 23.68H88.8L88.8 29.6H94.72V23.68Z' />
    <QrCodePath d='M112.48 23.68H106.56V29.6H112.48V23.68Z' />
    <QrCodePath d='M124.32 23.68H118.4V29.6H124.32V23.68Z' />
    <QrCodePath d='M130.24 23.68H124.32V29.6H130.24V23.68Z' />
    <QrCodePath d='M136.16 23.68H130.24V29.6H136.16V23.68Z' />
    <QrCodePath d='M148 23.68H142.08V29.6H148V23.68Z' />
    <QrCodePath d='M5.92 29.6H0V35.52H5.92V29.6Z' />
    <QrCodePath d='M41.44 29.6H35.52V35.52H41.44V29.6Z' />
    <QrCodePath d='M65.12 29.6H59.2V35.52H65.12V29.6Z' />
    <QrCodePath d='M71.04 29.6H65.12V35.52H71.04V29.6Z' />
    <QrCodePath d='M82.88 29.6H76.96V35.52H82.88V29.6Z' />
    <QrCodePath d='M88.8 29.6H82.88V35.52H88.8L88.8 29.6Z' />
    <QrCodePath d='M94.72 29.6H88.8L88.8 35.52H94.72V29.6Z' />
    <QrCodePath d='M112.48 29.6H106.56V35.52H112.48V29.6Z' />
    <QrCodePath d='M148 29.6H142.08V35.52H148V29.6Z' />
    <QrCodePath d='M5.92 35.52H0V41.44H5.92V35.52Z' />
    <QrCodePath d='M11.84 35.52L5.92 35.52V41.44H11.84V35.52Z' />
    <QrCodePath d='M17.76 35.52H11.84V41.44H17.76V35.52Z' />
    <QrCodePath d='M23.68 35.52H17.76V41.44H23.68V35.52Z' />
    <QrCodePath d='M29.6 35.52H23.68V41.44H29.6V35.52Z' />
    <QrCodePath d='M35.52 35.52L29.6 35.52V41.44H35.52L35.52 35.52Z' />
    <QrCodePath d='M41.44 35.52H35.52L35.52 41.44H41.44V35.52Z' />
    <QrCodePath d='M53.28 35.52H47.36V41.44H53.28V35.52Z' />
    <QrCodePath d='M65.12 35.52H59.2V41.44H65.12V35.52Z' />
    <QrCodePath d='M76.96 35.52H71.04V41.44H76.96V35.52Z' />
    <QrCodePath d='M88.8 35.52H82.88V41.44H88.8V35.52Z' />
    <QrCodePath d='M100.64 35.52L94.72 35.52V41.44H100.64V35.52Z' />
    <QrCodePath d='M112.48 35.52H106.56V41.44H112.48V35.52Z' />
    <QrCodePath d='M118.4 35.52L112.48 35.52V41.44H118.4V35.52Z' />
    <QrCodePath d='M124.32 35.52H118.4V41.44H124.32V35.52Z' />
    <QrCodePath d='M130.24 35.52H124.32V41.44H130.24V35.52Z' />
    <QrCodePath d='M136.16 35.52H130.24V41.44H136.16V35.52Z' />
    <QrCodePath d='M142.08 35.52L136.16 35.52V41.44H142.08V35.52Z' />
    <QrCodePath d='M148 35.52H142.08V41.44H148V35.52Z' />
    <QrCodePath d='M53.28 41.44H47.36V47.36H53.28V41.44Z' />
    <QrCodePath d='M59.2 41.44H53.28V47.36H59.2V41.44Z' />
    <QrCodePath d='M65.12 41.44H59.2V47.36H65.12V41.44Z' />
    <QrCodePath d='M71.04 41.44H65.12V47.36H71.04V41.44Z' />
    <QrCodePath d='M88.8 41.44H82.88V47.36H88.8V41.44Z' />
    <QrCodePath d='M94.72 41.44H88.8V47.36H94.72V41.44Z' />
    <QrCodePath d='M5.92 47.36H0V53.28H5.92V47.36Z' />
    <QrCodePath d='M17.76 47.36H11.84V53.28H17.76V47.36Z' />
    <QrCodePath d='M35.52 47.36H29.6V53.28H35.52V47.36Z' />
    <QrCodePath d='M41.44 47.36H35.52V53.28H41.44V47.36Z' />
    <QrCodePath d='M65.12 47.36H59.2V53.28H65.12V47.36Z' />
    <QrCodePath d='M71.04 47.36H65.12V53.28H71.04V47.36Z' />
    <QrCodePath d='M82.88 47.36H76.96V53.28H82.88V47.36Z' />
    <QrCodePath d='M94.72 47.36H88.8L88.8 53.28H94.72V47.36Z' />
    <QrCodePath d='M106.56 47.36H100.64V53.28H106.56V47.36Z' />
    <QrCodePath d='M112.48 47.36H106.56V53.28H112.48V47.36Z' />
    <QrCodePath d='M136.16 47.36H130.24V53.28H136.16V47.36Z' />
    <QrCodePath d='M148 47.36H142.08V53.28H148V47.36Z' />
    <QrCodePath d='M11.84 53.28H5.92V59.2H11.84V53.28Z' />
    <QrCodePath d='M29.6 53.28H23.68V59.2H29.6V53.28Z' />
    <QrCodePath d='M47.36 53.28H41.44V59.2H47.36V53.28Z' />
    <QrCodePath d='M71.04 53.28H65.12V59.2H71.04V53.28Z' />
    <QrCodePath d='M82.88 53.28H76.96V59.2H82.88V53.28Z' />
    <QrCodePath d='M106.56 53.28H100.64V59.2H106.56V53.28Z' />
    <QrCodePath d='M118.4 53.28H112.48V59.2H118.4V53.28Z' />
    <QrCodePath d='M130.24 53.28H124.32V59.2H130.24V53.28Z' />
    <QrCodePath d='M136.16 53.28H130.24V59.2H136.16V53.28Z' />
    <QrCodePath d='M142.08 53.28H136.16V59.2H142.08V53.28Z' />
    <QrCodePath d='M5.92 59.2H0V65.12H5.92V59.2Z' />
    <QrCodePath d='M29.6 59.2H23.68V65.12H29.6V59.2Z' />
    <QrCodePath d='M41.44 59.2H35.52V65.12H41.44V59.2Z' />
    <QrCodePath d='M65.12 59.2H59.2V65.12H65.12V59.2Z' />
    <QrCodePath d='M71.04 59.2H65.12V65.12H71.04V59.2Z' />
    <QrCodePath d='M76.96 59.2H71.04V65.12H76.96V59.2Z' />
    <QrCodePath d='M82.88 59.2H76.96V65.12H82.88V59.2Z' />
    <QrCodePath d='M106.56 59.2H100.64V65.12H106.56V59.2Z' />
    <QrCodePath d='M112.48 59.2H106.56V65.12H112.48V59.2Z' />
    <QrCodePath d='M118.4 59.2H112.48V65.12H118.4V59.2Z' />
    <QrCodePath d='M124.32 59.2H118.4V65.12H124.32V59.2Z' />
    <QrCodePath d='M130.24 59.2H124.32V65.12H130.24V59.2Z' />
    <QrCodePath d='M142.08 59.2H136.16V65.12H142.08V59.2Z' />
    <QrCodePath d='M148 59.2H142.08V65.12H148V59.2Z' />
    <QrCodePath d='M5.92 65.12H0V71.04H5.92V65.12Z' />
    <QrCodePath d='M17.76 65.12H11.84V71.04H17.76V65.12Z' />
    <QrCodePath d='M76.96 65.12H71.04V71.04H76.96V65.12Z' />
    <QrCodePath d='M94.72 65.12H88.8V71.04H94.72V65.12Z' />
    <QrCodePath d='M112.48 65.12H106.56V71.04H112.48V65.12Z' />
    <QrCodePath d='M124.32 65.12H118.4V71.04H124.32V65.12Z' />
    <QrCodePath d='M142.08 65.12H136.16V71.04H142.08V65.12Z' />
    <QrCodePath d='M11.84 71.04H5.92V76.96H11.84V71.04Z' />
    <QrCodePath d='M29.6 71.04H23.68V76.96H29.6V71.04Z' />
    <QrCodePath d='M41.44 71.04H35.52V76.96H41.44V71.04Z' />
    <QrCodePath d='M47.36 71.04H41.44V76.96H47.36V71.04Z' />
    <QrCodePath d='M53.28 71.04H47.36V76.96H53.28V71.04Z' />
    <QrCodePath d='M65.12 71.04H59.2V76.96H65.12V71.04Z' />
    <QrCodePath d='M76.96 71.04H71.04V76.96H76.96V71.04Z' />
    <QrCodePath d='M82.88 71.04H76.96V76.96H82.88V71.04Z' />
    <QrCodePath d='M88.8 71.04H82.88V76.96H88.8L88.8 71.04Z' />
    <QrCodePath d='M94.72 71.04H88.8L88.8 76.96H94.72V71.04Z' />
    <QrCodePath d='M124.32 71.04H118.4V76.96H124.32V71.04Z' />
    <QrCodePath d='M130.24 71.04H124.32V76.96H130.24V71.04Z' />
    <QrCodePath d='M136.16 71.04H130.24V76.96H136.16V71.04Z' />
    <QrCodePath d='M142.08 71.04H136.16V76.96H142.08V71.04Z' />
    <QrCodePath d='M148 71.04H142.08V76.96H148V71.04Z' />
    <QrCodePath d='M5.92 76.96H0V82.88H5.92V76.96Z' />
    <QrCodePath d='M11.84 76.96H5.92V82.88H11.84V76.96Z' />
    <QrCodePath d='M17.76 76.96H11.84V82.88H17.76V76.96Z' />
    <QrCodePath d='M23.68 76.96H17.76V82.88H23.68V76.96Z' />
    <QrCodePath d='M35.52 76.96H29.6V82.88H35.52L35.52 76.96Z' />
    <QrCodePath d='M65.12 76.96H59.2V82.88H65.12V76.96Z' />
    <QrCodePath d='M88.8 76.96H82.88V82.88H88.8V76.96Z' />
    <QrCodePath d='M112.48 76.96H106.56V82.88H112.48V76.96Z' />
    <QrCodePath d='M130.24 76.96H124.32V82.88H130.24V76.96Z' />
    <QrCodePath d='M148 76.96H142.08V82.88H148V76.96Z' />
    <QrCodePath d='M11.84 82.88H5.92V88.8H11.84V82.88Z' />
    <QrCodePath d='M29.6 82.88H23.68V88.8H29.6V82.88Z' />
    <QrCodePath d='M35.52 82.88H29.6V88.8H35.52V82.88Z' />
    <QrCodePath d='M41.44 82.88H35.52V88.8H41.44V82.88Z' />
    <QrCodePath d='M65.12 82.88H59.2V88.8H65.12V82.88Z' />
    <QrCodePath d='M71.04 82.88H65.12V88.8H71.04V82.88Z' />
    <QrCodePath d='M82.88 82.88H76.96V88.8H82.88V82.88Z' />
    <QrCodePath d='M88.8 82.88H82.88V88.8H88.8V82.88Z' />
    <QrCodePath d='M106.56 82.88H100.64V88.8H106.56V82.88Z' />
    <QrCodePath d='M112.48 82.88H106.56V88.8H112.48V82.88Z' />
    <QrCodePath d='M124.32 82.88H118.4V88.8H124.32V82.88Z' />
    <QrCodePath d='M142.08 82.88H136.16V88.8H142.08V82.88Z' />
    <QrCodePath d='M148 82.88H142.08V88.8H148V82.88Z' />
    <QrCodePath d='M47.36 88.8L41.44 88.8V94.72H47.36V88.8Z' />
    <QrCodePath d='M53.28 88.8H47.36V94.72H53.28V88.8Z' />
    <QrCodePath d='M59.2 88.8L53.28 88.8V94.72H59.2V88.8Z' />
    <QrCodePath d='M65.12 88.8H59.2V94.72H65.12V88.8Z' />
    <QrCodePath d='M71.04 88.8H65.12V94.72H71.04V88.8Z' />
    <QrCodePath d='M82.88 88.8H76.96V94.72H82.88V88.8Z' />
    <QrCodePath d='M88.8 88.8H82.88V94.72H88.8V88.8Z' />
    <QrCodePath d='M94.72 88.8L88.8 88.8V94.72H94.72V88.8Z' />
    <QrCodePath d='M124.32 88.8H118.4V94.72H124.32V88.8Z' />
    <QrCodePath d='M130.24 88.8L124.32 88.8V94.72H130.24V88.8Z' />
    <QrCodePath d='M136.16 88.8L130.24 88.8V94.72H136.16V88.8Z' />
    <QrCodePath d='M35.52 94.72H29.6V100.64H35.52V94.72Z' />
    <QrCodePath d='M41.44 94.72H35.52V100.64H41.44V94.72Z' />
    <QrCodePath d='M59.2 94.72H53.28V100.64H59.2V94.72Z' />
    <QrCodePath d='M65.12 94.72H59.2V100.64H65.12V94.72Z' />
    <QrCodePath d='M71.04 94.72H65.12V100.64H71.04V94.72Z' />
    <QrCodePath d='M76.96 94.72H71.04V100.64H76.96V94.72Z' />
    <QrCodePath d='M82.88 94.72H76.96V100.64H82.88V94.72Z' />
    <QrCodePath d='M88.8 94.72H82.88V100.64H88.8V94.72Z' />
    <QrCodePath d='M94.72 94.72H88.8V100.64H94.72V94.72Z' />
    <QrCodePath d='M100.64 94.72H94.72V100.64H100.64V94.72Z' />
    <QrCodePath d='M106.56 94.72H100.64V100.64H106.56V94.72Z' />
    <QrCodePath d='M112.48 94.72H106.56V100.64H112.48V94.72Z' />
    <QrCodePath d='M118.4 94.72H112.48V100.64H118.4V94.72Z' />
    <QrCodePath d='M124.32 94.72H118.4V100.64H124.32V94.72Z' />
    <QrCodePath d='M136.16 94.72H130.24V100.64H136.16V94.72Z' />
    <QrCodePath d='M142.08 94.72H136.16V100.64H142.08V94.72Z' />
    <QrCodePath d='M148 94.72H142.08V100.64H148V94.72Z' />
    <QrCodePath d='M65.12 100.64H59.2V106.56H65.12V100.64Z' />
    <QrCodePath d='M94.72 100.64H88.8L88.8 106.56H94.72V100.64Z' />
    <QrCodePath d='M100.64 100.64H94.72V106.56H100.64V100.64Z' />
    <QrCodePath d='M124.32 100.64H118.4V106.56H124.32V100.64Z' />
    <QrCodePath d='M130.24 100.64H124.32V106.56H130.24V100.64Z' />
    <QrCodePath d='M142.08 100.64H136.16V106.56H142.08V100.64Z' />
    <QrCodePath d='M148 100.64H142.08V106.56H148V100.64Z' />
    <QrCodePath d='M5.92 106.56H0V112.48H5.92V106.56Z' />
    <QrCodePath d='M11.84 106.56H5.92V112.48H11.84V106.56Z' />
    <QrCodePath d='M17.76 106.56H11.84V112.48H17.76V106.56Z' />
    <QrCodePath d='M23.68 106.56H17.76V112.48H23.68V106.56Z' />
    <QrCodePath d='M29.6 106.56H23.68V112.48H29.6V106.56Z' />
    <QrCodePath d='M35.52 106.56H29.6V112.48H35.52V106.56Z' />
    <QrCodePath d='M41.44 106.56H35.52V112.48H41.44V106.56Z' />
    <QrCodePath d='M59.2 106.56H53.28V112.48H59.2V106.56Z' />
    <QrCodePath d='M65.12 106.56H59.2V112.48H65.12V106.56Z' />
    <QrCodePath d='M71.04 106.56H65.12V112.48H71.04V106.56Z' />
    <QrCodePath d='M76.96 106.56H71.04V112.48H76.96V106.56Z' />
    <QrCodePath d='M82.88 106.56H76.96V112.48H82.88V106.56Z' />
    <QrCodePath d='M88.8 106.56H82.88V112.48H88.8L88.8 106.56Z' />
    <QrCodePath d='M100.64 106.56H94.72V112.48H100.64V106.56Z' />
    <QrCodePath d='M112.48 106.56H106.56V112.48H112.48V106.56Z' />
    <QrCodePath d='M124.32 106.56H118.4V112.48H124.32V106.56Z' />
    <QrCodePath d='M148 106.56H142.08V112.48H148V106.56Z' />
    <QrCodePath d='M5.92 112.48H0V118.4H5.92V112.48Z' />
    <QrCodePath d='M41.44 112.48H35.52L35.52 118.4H41.44V112.48Z' />
    <QrCodePath d='M53.28 112.48H47.36V118.4H53.28V112.48Z' />
    <QrCodePath d='M59.2 112.48H53.28V118.4H59.2V112.48Z' />
    <QrCodePath d='M71.04 112.48H65.12V118.4H71.04V112.48Z' />
    <QrCodePath d='M76.96 112.48H71.04V118.4H76.96V112.48Z' />
    <QrCodePath d='M94.72 112.48H88.8L88.8 118.4H94.72V112.48Z' />
    <QrCodePath d='M100.64 112.48H94.72V118.4H100.64V112.48Z' />
    <QrCodePath d='M124.32 112.48H118.4V118.4H124.32V112.48Z' />
    <QrCodePath d='M136.16 112.48H130.24V118.4H136.16V112.48Z' />
    <QrCodePath d='M148 112.48H142.08V118.4H148V112.48Z' />
    <QrCodePath d='M5.92 118.4H0V124.32H5.92V118.4Z' />
    <QrCodePath d='M17.76 118.4H11.84V124.32H17.76V118.4Z' />
    <QrCodePath d='M23.68 118.4H17.76V124.32H23.68V118.4Z' />
    <QrCodePath d='M29.6 118.4H23.68V124.32H29.6V118.4Z' />
    <QrCodePath d='M41.44 118.4H35.52V124.32H41.44V118.4Z' />
    <QrCodePath d='M71.04 118.4H65.12V124.32H71.04V118.4Z' />
    <QrCodePath d='M94.72 118.4H88.8V124.32H94.72V118.4Z' />
    <QrCodePath d='M100.64 118.4H94.72V124.32H100.64V118.4Z' />
    <QrCodePath d='M106.56 118.4H100.64V124.32H106.56V118.4Z' />
    <QrCodePath d='M112.48 118.4H106.56V124.32H112.48V118.4Z' />
    <QrCodePath d='M118.4 118.4H112.48V124.32H118.4V118.4Z' />
    <QrCodePath d='M124.32 118.4H118.4V124.32H124.32V118.4Z' />
    <QrCodePath d='M130.24 118.4H124.32V124.32H130.24V118.4Z' />
    <QrCodePath d='M136.16 118.4H130.24V124.32H136.16V118.4Z' />
    <QrCodePath d='M142.08 118.4H136.16V124.32H142.08V118.4Z' />
    <QrCodePath d='M5.92 124.32H0V130.24H5.92V124.32Z' />
    <QrCodePath d='M17.76 124.32H11.84V130.24H17.76V124.32Z' />
    <QrCodePath d='M23.68 124.32H17.76V130.24H23.68V124.32Z' />
    <QrCodePath d='M29.6 124.32H23.68V130.24H29.6V124.32Z' />
    <QrCodePath d='M41.44 124.32H35.52V130.24H41.44V124.32Z' />
    <QrCodePath d='M59.2 124.32H53.28V130.24H59.2V124.32Z' />
    <QrCodePath d='M65.12 124.32H59.2V130.24H65.12V124.32Z' />
    <QrCodePath d='M71.04 124.32H65.12V130.24H71.04V124.32Z' />
    <QrCodePath d='M82.88 124.32H76.96V130.24H82.88V124.32Z' />
    <QrCodePath d='M88.8 124.32H82.88V130.24H88.8L88.8 124.32Z' />
    <QrCodePath d='M94.72 124.32H88.8L88.8 130.24H94.72V124.32Z' />
    <QrCodePath d='M112.48 124.32H106.56V130.24H112.48V124.32Z' />
    <QrCodePath d='M118.4 124.32H112.48V130.24H118.4V124.32Z' />
    <QrCodePath d='M5.92 130.24H0V136.16H5.92V130.24Z' />
    <QrCodePath d='M17.76 130.24H11.84V136.16H17.76V130.24Z' />
    <QrCodePath d='M23.68 130.24H17.76V136.16H23.68V130.24Z' />
    <QrCodePath d='M29.6 130.24H23.68V136.16H29.6V130.24Z' />
    <QrCodePath d='M41.44 130.24H35.52V136.16H41.44V130.24Z' />
    <QrCodePath d='M53.28 130.24H47.36V136.16H53.28V130.24Z' />
    <QrCodePath d='M65.12 130.24H59.2V136.16H65.12V130.24Z' />
    <QrCodePath d='M88.8 130.24H82.88V136.16H88.8V130.24Z' />
    <QrCodePath d='M112.48 130.24H106.56V136.16H112.48V130.24Z' />
    <QrCodePath d='M5.92 136.16H0V142.08H5.92V136.16Z' />
    <QrCodePath d='M41.44 136.16H35.52V142.08H41.44V136.16Z' />
    <QrCodePath d='M59.2 136.16H53.28V142.08H59.2V136.16Z' />
    <QrCodePath d='M76.96 136.16H71.04V142.08H76.96V136.16Z' />
    <QrCodePath d='M100.64 136.16H94.72V142.08H100.64V136.16Z' />
    <QrCodePath d='M112.48 136.16H106.56V142.08H112.48V136.16Z' />
    <QrCodePath d='M118.4 136.16H112.48V142.08H118.4V136.16Z' />
    <QrCodePath d='M130.24 136.16H124.32V142.08H130.24V136.16Z' />
    <QrCodePath d='M136.16 136.16H130.24V142.08H136.16V136.16Z' />
    <QrCodePath d='M5.92 142.08H0V148H5.92V142.08Z' />
    <QrCodePath d='M11.84 142.08H5.92V148H11.84V142.08Z' />
    <QrCodePath d='M17.76 142.08H11.84V148H17.76V142.08Z' />
    <QrCodePath d='M23.68 142.08H17.76V148H23.68V142.08Z' />
    <QrCodePath d='M29.6 142.08H23.68V148H29.6V142.08Z' />
    <QrCodePath d='M35.52 142.08H29.6V148H35.52L35.52 142.08Z' />
    <QrCodePath d='M41.44 142.08H35.52L35.52 148H41.44V142.08Z' />
    <QrCodePath d='M53.28 142.08H47.36V148H53.28V142.08Z' />
    <QrCodePath d='M59.2 142.08H53.28V148H59.2V142.08Z' />
    <QrCodePath d='M65.12 142.08H59.2V148H65.12V142.08Z' />
    <QrCodePath d='M82.88 142.08H76.96V148H82.88V142.08Z' />
    <QrCodePath d='M88.8 142.08H82.88V148H88.8V142.08Z' />
    <QrCodePath d='M100.64 142.08H94.72V148H100.64V142.08Z' />
    <QrCodePath d='M106.56 142.08H100.64V148H106.56V142.08Z' />
    <QrCodePath d='M112.48 142.08H106.56V148H112.48V142.08Z' />
    <QrCodePath d='M118.4 142.08H112.48V148H118.4V142.08Z' />
    <QrCodePath d='M124.32 142.08H118.4V148H124.32V142.08Z' />
    <QrCodePath d='M136.16 142.08H130.24V148H136.16V142.08Z' />
    <QrCodePath d='M148 142.08H142.08V148H148V142.08Z' />
  </Svg>
)
