import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Chat: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='32'
    height='32'
    viewBox='0 0 700 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='m447.44 72.801h-189.28c-57.68 0-104.16 46.48-104.16 104.16v122.08c0 45.922 29.68 85.121 71.121 98.559 14.559 4.4805 24.078 17.922 24.078 33.039v33.602c0 8.9609 10.641 14.559 17.922 8.9609l81.762-63.281c6.1602-4.4805 13.441-7.2812 21.281-7.2812h77.281c57.676 0.5625 104.16-45.918 104.16-103.6v-122.08c0-57.68-46.48-104.16-104.16-104.16z'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      className='primary fill'
    />
  </Svg>
)
