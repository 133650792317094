import { Svg, SvgPath } from 'primitives/Svg'
import { theme } from 'lib/theme'
import { SvgIconProps } from '.'

export const Purchases: React.FC<SvgIconProps> = ({ css }) => {
  return (
    <Svg
      width="40px"
      height="44px"
      version="1.1"
      viewBox="0 0 752 752"
      xmlns="http://www.w3.org/2000/svg"
      css={css}
    >
      <SvgPath
        className="primary fill"
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor
        }}
        d="m309.4 376c0 17.664 7.0195 34.602 19.508 47.094 12.488 12.488 29.43 19.504 47.09 19.504 17.664 0 34.602-7.0156 47.094-19.504 12.488-12.492 19.504-29.43 19.504-47.094 0-17.66-7.0156-34.602-19.504-47.09-12.492-12.488-29.43-19.508-47.094-19.508-17.648 0.039062-34.566 7.0703-47.047 19.551s-19.512 29.398-19.551 47.047zm118.39 0h0.003906c0 13.738-5.457 26.914-15.172 36.629s-22.891 15.172-36.629 15.172c-13.734 0-26.91-5.457-36.625-15.172s-15.172-22.891-15.172-36.629c0-13.734 5.457-26.91 15.172-36.625s22.891-15.172 36.625-15.172c13.738 0 26.914 5.457 36.629 15.172s15.172 22.891 15.172 36.625z"
      />
      <SvgPath
        className="primary fill"
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor
        }}
        d="m553.59 427.8c-16.332 0.046875-32.078 6.1094-44.227 17.027-12.148 10.922-19.848 25.934-21.629 42.168h-223.47c-1.6562-14.934-8.3477-28.859-18.973-39.484s-24.551-17.316-39.484-18.973v-105.07c14.934-1.6562 28.859-8.3477 39.484-18.973s17.316-24.551 18.973-39.484h223.47c1.7812 16.234 9.4805 31.246 21.629 42.164 12.148 10.922 27.895 16.984 44.227 17.031 1.9648 0 3.8438-0.77734 5.2344-2.168 1.3867-1.3867 2.1641-3.2695 2.1641-5.2305v-44.398c0-5.8867-2.3359-11.535-6.5-15.699-4.1641-4.1602-9.8086-6.5-15.699-6.5h-325.59c-5.8867 0-11.531 2.3398-15.695 6.5-4.1641 4.1641-6.5039 9.8125-6.5039 15.699v207.19c0 5.8867 2.3398 11.535 6.5039 15.695 4.1641 4.1641 9.8086 6.5039 15.695 6.5039h325.59c5.8906 0 11.535-2.3398 15.699-6.5039 4.1641-4.1602 6.5-9.8086 6.5-15.695v-44.398c0-1.9609-0.77734-3.8438-2.1641-5.2344-1.3906-1.3867-3.2695-2.1641-5.2344-2.1641zm-7.3984-155.39v36.258c-11.02-1.582-21.227-6.6914-29.098-14.562-7.8711-7.8711-12.98-18.078-14.562-29.094h36.258c1.9648 0 3.8477 0.77734 5.2344 2.1641 1.3867 1.3906 2.168 3.2695 2.168 5.2344zm-332.99-7.3984h36.258c-1.582 11.016-6.6875 21.223-14.559 29.094-7.8711 7.8711-18.082 12.98-29.098 14.562v-36.258c0-4.0859 3.3125-7.3984 7.3984-7.3984zm-7.3984 214.59v-36.258c11.152 1.4336 21.527 6.4766 29.543 14.359 8.0156 7.8828 13.234 18.172 14.855 29.297h-37c-4.0859 0-7.3984-3.3125-7.3984-7.3984zm332.99 7.3984h-36.262c1.582-11.016 6.6914-21.227 14.562-29.098 7.8711-7.8672 18.078-12.977 29.098-14.559v36.258c0 1.9609-0.78125 3.8438-2.168 5.2305-1.3867 1.3906-3.2695 2.168-5.2344 2.168z"
      />
      <SvgPath
        className="primary fill"
        css={{
          fill: theme.colors.gray800,
          transition: theme.transitions.iconColor
        }}
        d="m579.34 379.7c0.73438-1.8555 0.73438-3.918 0-5.7734-0.34766-0.90625-0.90625-1.7148-1.625-2.3672l-30.34-29.598v-0.003906c-1.3438-1.4297-3.2188-2.2422-5.1797-2.2422-1.9609 0-3.8359 0.8125-5.1797 2.2422-1.4023 1.3906-2.1875 3.2812-2.1875 5.2539 0 1.9766 0.78516 3.8672 2.1875 5.2539l17.758 17.609-79.324 0.003906c-4.0859 0-7.3984 3.3125-7.3984 7.3984 0 1.9648 0.77734 3.8438 2.168 5.2344 1.3867 1.3867 3.2695 2.168 5.2305 2.168h79.324l-17.758 17.758c-1.4023 1.3906-2.1875 3.2812-2.1875 5.2539 0 1.9727 0.78516 3.8633 2.1875 5.2539 1.3555 1.4062 3.2227 2.207 5.1797 2.2188 1.9609 0.023437 3.8438-0.78125 5.1797-2.2188l30.34-30.34c0.82812-0.85938 1.3945-1.9375 1.625-3.1055z"
      />
    </Svg>
  )
}
