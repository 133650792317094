import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Video: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='M10.8 9.6L11.0977 9.07906C10.912 8.97294 10.6838 8.9737 10.4988 9.08105C10.3139 9.18841 10.2 9.38611 10.2 9.6H10.8ZM10.8 14.4H10.2C10.2 14.6139 10.3139 14.8116 10.4988 14.919C10.6838 15.0263 10.912 15.0271 11.0977 14.9209L10.8 14.4ZM15 12L15.2977 12.5209C15.4847 12.4141 15.6 12.2153 15.6 12C15.6 11.7847 15.4847 11.5859 15.2977 11.4791L15 12ZM12 19.8C7.69218 19.8 4.2 16.3079 4.2 12H3C3 16.9705 7.02943 21 12 21V19.8ZM19.8 12C19.8 16.3079 16.3079 19.8 12 19.8V21C16.9705 21 21 16.9705 21 12H19.8ZM12 4.2C16.3079 4.2 19.8 7.69218 19.8 12H21C21 7.02943 16.9705 3 12 3V4.2ZM12 3C7.02943 3 3 7.02943 3 12H4.2C4.2 7.69218 7.69218 4.2 12 4.2V3ZM10.2 9.6V14.4H11.4V9.6H10.2ZM11.0977 14.9209L15.2977 12.5209L14.7023 11.4791L10.5023 13.8791L11.0977 14.9209ZM15.2977 11.4791L11.0977 9.07906L10.5023 10.1209L14.7023 12.5209L15.2977 11.4791Z'
      fill='white'
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
    />
  </Svg>
)
