import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '..'

export const BadgeUSDC: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='M12 24C18.65 24 24 18.65 24 12C24 5.34996 18.65 0 12 0C5.34996 0 0 5.34996 0 12C0 18.65 5.34996 24 12 24Z'
      className='primary fill'
      fill='#2775CA'
    />
    <SvgPath
      d='M15.3001 13.9001C15.3001 12.1501 14.2501 11.5501 12.1501 11.3002C10.6501 11.1001 10.3501 10.7002 10.3501 10.0001C10.3501 9.30002 10.8501 8.85014 11.8501 8.85014C12.7501 8.85014 13.2501 9.15014 13.5001 9.90014C13.5501 10.0501 13.7001 10.1501 13.8501 10.1501H14.65C14.8501 10.1501 15.0001 10.0001 15.0001 9.80018V9.75014C14.8 8.6501 13.9 7.80014 12.7501 7.70018V6.50018C12.7501 6.30014 12.6001 6.15014 12.3501 6.1001H11.6001C11.4001 6.1001 11.2501 6.2501 11.2 6.50018V7.65014C9.70005 7.85018 8.75013 8.85014 8.75013 10.1002C8.75013 11.7502 9.75009 12.4001 11.8501 12.6502C13.2501 12.9001 13.7001 13.2001 13.7001 14.0002C13.7001 14.8002 13 15.3502 12.0501 15.3502C10.75 15.3502 10.3 14.8001 10.15 14.0501C10.1001 13.8502 9.95013 13.7501 9.80013 13.7501H8.95005C8.75013 13.7501 8.60013 13.9001 8.60013 14.1001V14.1502C8.80005 15.4001 9.60009 16.3001 11.2501 16.5502V17.7502C11.2501 17.9501 11.4001 18.1001 11.65 18.1501H12.4C12.6001 18.1501 12.7501 18.0001 12.8001 17.7502V16.5502C14.3001 16.3001 15.3001 15.2501 15.3001 13.9001Z'
      fill='white'
    />
    <SvgPath
      d='M9.45003 19.1499C5.55003 17.75 3.54999 13.4 5.00007 9.54992C5.75007 7.44992 7.40007 5.84996 9.45003 5.09996C9.65007 5 9.75003 4.85 9.75003 4.59992V3.89996C9.75003 3.69992 9.65007 3.54992 9.45003 3.5C9.39999 3.5 9.30003 3.5 9.24999 3.54992C4.50003 5.04992 1.89999 10.1 3.39999 14.85C4.29999 17.6499 6.45003 19.8 9.24999 20.7C9.45003 20.7999 9.65007 20.7 9.69999 20.4999C9.75003 20.45 9.75003 20.4 9.75003 20.3V19.5999C9.75003 19.4499 9.60003 19.25 9.45003 19.1499ZM14.7501 3.54992C14.55 3.44996 14.35 3.54992 14.3001 3.74996C14.25 3.8 14.25 3.84992 14.25 3.95V4.64996C14.25 4.85 14.4 5.04992 14.55 5.15C18.45 6.54992 20.4501 10.8999 19 14.75C18.25 16.85 16.6 18.45 14.55 19.2C14.35 19.2999 14.25 19.4499 14.25 19.7V20.4C14.25 20.6 14.35 20.75 14.55 20.7999C14.6001 20.7999 14.7 20.7999 14.7501 20.75C19.5 19.25 22.1001 14.1999 20.6001 9.44996C19.7001 6.59996 17.5 4.44992 14.7501 3.54992Z'
      fill='white'
    />
  </Svg>
)
