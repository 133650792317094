import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Send: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='M17.3781 7.99381C17.7593 8.18439 18 8.57386 18 9.00001C18 9.42612 17.7593 9.81566 17.3781 10.0062L1.62813 17.8812C1.22909 18.0807 0.74984 18.0256 0.406603 17.7407C0.0632534 17.4558 -0.0792847 16.9949 0.0433407 16.5659L1.6504 10.9409C1.78844 10.458 2.22989 10.125 2.7322 10.125H7.87502C8.49635 10.125 9.00001 9.62133 9.00001 9.00001C9.00001 8.37868 8.49635 7.87501 7.87502 7.87501L2.7322 7.87501C2.22989 7.87501 1.78844 7.54202 1.6504 7.05905L0.0433407 1.43406C-0.0792847 1.0051 0.0632534 0.544188 0.406603 0.259338C0.74984 -0.0256252 1.22909 -0.0807476 1.62813 0.118826L17.3781 7.99381Z'
      className='primary fill'
      css={{
        fill: theme.colors.voilet100,
      }}
    />
  </Svg>
)
