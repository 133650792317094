import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Profile: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='20'
    height='20'
    viewBox='0 0 700 600'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      fillRule='evenodd'
      clipRule='evenodd'
      d='m350 260.96c-56 0-101.92-45.359-101.92-101.92 0-56 45.922-101.92 101.92-101.92s101.92 45.359 101.92 101.92c0 56-45.922 101.92-101.92 101.92z'
      className='primary fill'
      css={{
        fill: theme.colors.gray900,
        transition: theme.transitions.iconColor,
      }}
    />
    <SvgPath
      fillRule='evenodd'
      clipRule='evenodd'
      d='m548.24 502.32h-396.48v-28c0-104.72 85.68-190.4 190.4-190.4h15.68c104.72 0 190.4 85.68 190.4 190.4v28z'
      className='primary fill'
      css={{
        fill: theme.colors.gray900,
        transition: theme.transitions.iconColor,
      }}
    />
  </Svg>
)
