import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const MetamaskOutline: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='22px'
    height='22px'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <path
      d='M28.4231 1.18018L16.7891 9.82018L18.9401 4.72218L28.4231 1.18018Z'
      fill='#E2761B'
      stroke='#E2761B'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.56489 1.18018L13.1049 9.90218L11.0599 4.72218L1.56489 1.18018ZM24.2369 21.2092L21.1389 25.9562L27.7689 27.7802L29.6739 21.3142L24.2369 21.2092ZM0.337891 21.3142L2.23189 27.7802L8.86189 25.9562L5.76189 21.2092L0.337891 21.3142Z'
      fill='#E4761B'
      stroke='#E4761B'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.48689 13.1882L6.63989 15.9822L13.2229 16.2752L12.9889 9.20116L8.48689 13.1882ZM21.4999 13.1882L16.9399 9.11816L16.7889 16.2742L23.3599 15.9822L21.4999 13.1882ZM8.86089 25.9562L12.8129 24.0262L9.39989 21.3622L8.86189 25.9572L8.86089 25.9562ZM17.1749 24.0272L21.1379 25.9562L20.5889 21.3612L17.1749 24.0272Z'
      fill='#E4761B'
      stroke='#E4761B'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.1391 25.9559L17.1751 24.0259L17.4911 26.6109L17.4551 27.6979L21.1391 25.9559ZM8.86108 25.9559L12.5451 27.6979L12.5211 26.6109L12.8131 24.0269L8.86108 25.9559Z'
      fill='#D7C1B3'
      stroke='#D7C1B3'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.6029 19.6541L9.30591 18.6841L11.6329 17.6191L12.6029 19.6541ZM17.3849 19.6541L18.3549 17.6191L20.6949 18.6831L17.3849 19.6531V19.6541Z'
      fill='#233447'
      stroke='#233447'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.86094 25.9559L9.42294 21.2089L5.76294 21.3139L8.86094 25.9559ZM20.5769 21.2089L21.1379 25.9559L24.2369 21.3139L20.5769 21.2089ZM23.3599 15.9819L16.7889 16.2749L17.3969 19.6539L18.3669 17.6189L20.7059 18.6829L23.3599 15.9829V15.9819ZM9.30494 18.6829L11.6429 17.6189L12.6029 19.6539L13.2219 16.2739L6.63894 15.9819L9.30494 18.6829Z'
      fill='#CD6116'
      stroke='#CD6116'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.63989 15.9819L9.39989 21.3609L9.30589 18.6829L6.63989 15.9829V15.9819ZM20.7059 18.6829L20.5889 21.3609L23.3599 15.9819L20.7059 18.6829ZM13.2229 16.2739L12.6029 19.6539L13.3749 23.6409L13.5499 18.3909L13.2229 16.2749V16.2739ZM16.7889 16.2739L16.4729 18.3789L16.6129 23.6409L17.3969 19.6539L16.7889 16.2739Z'
      fill='#E4751F'
      stroke='#E4751F'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.3969 19.6541L16.6129 23.6411L17.1749 24.0271L20.5889 21.3611L20.7059 18.6831L17.3959 19.6531L17.3969 19.6541ZM9.30591 18.6831L9.39891 21.3611L12.8139 24.0271L13.3739 23.6411L12.6029 19.6541L9.30591 18.6841V18.6831Z'
      fill='#F6851B'
      stroke='#F6851B'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.4551 27.6981L17.4911 26.6111L17.1981 26.3531H12.7901L12.5211 26.6111L12.5451 27.6981L8.86108 25.9561L10.1481 27.0081L12.7551 28.8211H17.2331L19.8531 27.0081L21.1381 25.9561L17.4551 27.6981Z'
      fill='#C0AD9E'
      stroke='#C0AD9E'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.175 24.0271L16.613 23.6411H13.375L12.813 24.0271L12.521 26.6111L12.79 26.3531H17.198L17.49 26.6111L17.175 24.0271Z'
      fill='#161616'
      stroke='#161616'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M28.914 10.3822L29.908 5.61118L28.423 1.18018L17.175 9.52818L21.501 13.1882L27.616 14.9772L28.973 13.3982L28.388 12.9782L29.323 12.1242L28.598 11.5622L29.534 10.8492L28.914 10.3822ZM0.092041 5.61118L1.08604 10.3812L0.455041 10.8492L1.39004 11.5622L0.677041 12.1242L1.61204 12.9772L1.02704 13.3982L2.37204 14.9772L8.48704 13.1872L12.813 9.52718L1.56604 1.18018L0.092041 5.61018V5.61118Z'
      fill='#763D16'
      stroke='#763D16'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M27.6159 14.9772L21.5009 13.1872L23.3609 15.9822L20.5889 21.3612L24.2369 21.3142H29.6739L27.6159 14.9772ZM8.48689 13.1882L2.37189 14.9772L0.337891 21.3142H5.76289L9.39889 21.3612L6.63889 15.9822L8.48689 13.1882ZM16.7889 16.2742L17.1749 9.52817L18.9519 4.72217H11.0599L12.8129 9.52817L13.2229 16.2742L13.3629 18.4022L13.3749 23.6412H16.6129L16.6369 18.4032L16.7889 16.2752V16.2742Z'
      fill='#F6851B'
      stroke='#F6851B'
      strokeWidth='0.117'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
)
