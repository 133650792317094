import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '..'

export const BadgeDAI: React.FC<SvgIconProps> = ({ css }) => {
  return (
    <Svg
      width='220'
      height='220'
      viewBox='0 0 220 220'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      css={css}
    >
      <g clipPath='url(#clip0_108_124)'>
        <SvgPath
          d='M110 220C170.751 220 220 170.751 220 110C220 49.2487 170.751 0 110 0C49.2487 0 0 49.2487 0 110C0 170.751 49.2487 220 110 220Z'
          fill='#F4B731'
        />
        <SvgPath
          fillRule='evenodd'
          clipRule='evenodd'
          d='M63.7794 55H108.824C136.221 55 156.991 69.5475 164.718 90.7088H178.75V103.503H167.674C167.887 105.524 167.998 107.587 167.998 109.677V109.993C167.998 112.344 167.86 114.668 167.585 116.937H178.75V129.724H164.45C156.523 150.597 135.919 165 108.831 165H63.7794V129.724H48.125V116.937H63.7794V103.503H48.125V90.7156H63.7794V55ZM76.3675 129.724V153.526H108.818C128.851 153.526 143.729 143.99 150.652 129.724H76.3675ZM154.509 116.937H76.3675V103.503H154.536C154.818 105.614 154.969 107.779 154.969 109.993V110.303C154.969 112.564 154.811 114.771 154.509 116.93V116.937ZM108.831 66.4469C128.948 66.4469 143.873 76.2369 150.755 90.7019H76.3675V66.4538H108.818L108.831 66.4469Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_108_124'>
          <rect width='220' height='220' fill='white' />
        </clipPath>
      </defs>
    </Svg>
  )
}
