import { DEFAULT_META_TITLE, META_TITLE_SUFFIX } from 'config/seo'

export const getPageTitle = (title?: string | null) => {
  return title ? `${title}${META_TITLE_SUFFIX}` : DEFAULT_META_TITLE
}

export const getErrorPageTitle = (status?: number | string) => {
  switch (status) {
    case 404:
      return getPageTitle('Page not found')
    default:
      return getPageTitle('Something went wrong')
  }
}
