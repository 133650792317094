import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Stack: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='101'
    height='102'
    viewBox='0 0 101 102'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      d='M50.3401 101.89L100.34 82.8414L72.7769 72.3411L100.34 61.8409L72.7769 51.3406L100.34 40.8404L72.7769 30.3401L100.34 19.8398L50.3401 0.791229L0.340088 19.8398L27.9033 30.3401L0.340088 40.8404L27.9033 51.3406L0.340088 61.8409L27.9033 72.3411L0.340088 82.8414L50.3401 101.89ZM12.1379 19.8398L50.3401 5.28628L88.5423 19.8398L50.3401 34.3934L33.8024 28.0928L12.1379 19.8398ZM12.1379 40.8404L33.8022 32.5871L50.3399 38.8877L66.8776 32.5871L88.5419 40.8404L50.3397 55.3939L33.802 49.0934L12.1379 40.8404ZM12.1379 61.8409L33.8022 53.5877L50.3399 59.8882L66.8776 53.5877L88.5419 61.8409L50.3397 76.3944L33.802 70.0939L12.1379 61.8409ZM66.878 74.5882L88.5423 82.8414L50.3401 97.395L12.1379 82.8414L33.8022 74.5882L50.3399 80.8887L66.878 74.5882Z'
      fill='black'
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
    />
  </Svg>
)
