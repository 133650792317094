import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Share: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='20'
    height='20'
    viewBox='0 0 21 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      className='primary fill'
      css={{
        fill: '$gray800',
        transition: theme.transitions.iconColor,
        stroke: 'transparent !important',
      }}
      d='M10.7306 0.499976C10.7164 0.485486 10.7015 0.471543 10.6862 0.458183C10.6246 0.404559 10.5568 0.362036 10.4852 0.330615C10.3878 0.287725 10.28 0.263916 10.1667 0.263916C10.0645 0.263916 9.96675 0.283292 9.87705 0.318573C9.77863 0.357186 9.68639 0.416258 9.60686 0.49579L5.38463 4.71801C5.07547 5.02718 5.07547 5.52843 5.38463 5.8376C5.6938 6.14676 6.19506 6.14676 6.50422 5.8376L9.37501 2.9668V14.7778C9.37501 15.215 9.72946 15.5695 10.1667 15.5695C10.6039 15.5695 10.9583 15.215 10.9583 14.7778V2.96687L13.8291 5.8376C14.1382 6.14676 14.6395 6.14676 14.9487 5.8376C15.2578 5.52843 15.2578 5.02718 14.9487 4.71801L10.7306 0.499976ZM1.72227 10.8194C2.1595 10.8194 2.51394 11.1739 2.51394 11.6111V20.0556C2.51394 20.4055 2.65295 20.7411 2.90039 20.9885C3.14784 21.236 3.48344 21.375 3.83338 21.375H16.5C16.85 21.375 17.1856 21.236 17.433 20.9885C17.6805 20.7411 17.8195 20.4055 17.8195 20.0556V11.6111C17.8195 11.1739 18.1739 10.8194 18.6112 10.8194C19.0484 10.8194 19.4028 11.1739 19.4028 11.6111V20.0556C19.4028 20.8254 19.097 21.5638 18.5526 22.1081C18.0082 22.6525 17.2699 22.9583 16.5 22.9583H3.83338C3.06352 22.9583 2.32518 22.6525 1.78081 22.1081C1.23643 21.5638 0.930603 20.8254 0.930603 20.0556V11.6111C0.930603 11.1739 1.28504 10.8194 1.72227 10.8194Z'
    />
  </Svg>
)
