import { theme } from 'lib/theme'
import { Svg, SvgPath } from 'primitives/Svg'
import { SvgIconProps } from '.'

export const Earnings: React.FC<SvgIconProps> = ({ css }) => (
  <Svg
    width='40px'
    height='45px'
    version='1.1'
    viewBox='80 90 552 552'
    xmlns='http://www.w3.org/2000/svg'
    css={css}
  >
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m402.05 309.7c0-0.62891-0.24609-1.2305-0.69141-1.6719-0.44531-0.44531-1.0469-0.69531-1.6758-0.69531h-142.07c-3.9219 0-7.1016 3.1797-7.1016 7.1055v123.13c0 3.9219 3.1797 7.1055 7.1016 7.1055h28.414c1.3086 0 2.3711-1.0625 2.3711-2.3672 0-1.3086-1.0625-2.3711-2.3711-2.3711h-28.414c-1.3086 0-2.3672-1.0586-2.3672-2.3672v-123.13c0-1.3086 1.0586-2.3711 2.3672-2.3711h142.07c0.62891 0 1.2305-0.24609 1.6758-0.69141 0.44531-0.44531 0.69141-1.0469 0.69141-1.6758z'
    />
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m501.5 437.57v-123.13c0-3.9258-3.1797-7.1055-7.1016-7.1055h-85.246c-1.3086 0-2.3672 1.0586-2.3672 2.3672 0 1.3086 1.0586 2.3672 2.3672 2.3672h85.246c0.625 0 1.2266 0.25 1.6719 0.69531 0.44531 0.44531 0.69531 1.0469 0.69531 1.6758v123.13c0 0.62891-0.25 1.2305-0.69531 1.6758-0.44531 0.44141-1.0469 0.69141-1.6719 0.69141h-198.91c-1.3086 0-2.3672 1.0625-2.3672 2.3711 0 1.3047 1.0586 2.3672 2.3672 2.3672h198.91c3.9219 0 7.1016-3.1836 7.1016-7.1055z'
    />
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m492.03 300.23c0-0.625-0.25-1.2266-0.69141-1.6719-0.44531-0.44531-1.0469-0.69531-1.6758-0.69531h-161.02c-1.3086 0-2.3672 1.0625-2.3672 2.3672 0 1.3086 1.0586 2.3711 2.3672 2.3711h161.02c0.62891 0 1.2305-0.25 1.6758-0.69531 0.44141-0.44531 0.69141-1.0469 0.69141-1.6758z'
    />
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m321.54 300.23c0-0.625-0.25-1.2266-0.69141-1.6719-0.44531-0.44531-1.0469-0.69531-1.6758-0.69531h-71.039c-3.9219 0-7.1016 3.1797-7.1016 7.1055v127.87c0 1.3086 1.0586 2.3672 2.3672 2.3672 1.3086 0 2.3672-1.0586 2.3672-2.3672v-127.87c0-1.3086 1.0625-2.3672 2.3672-2.3672h71.039c0.62891 0 1.2305-0.25 1.6758-0.69531 0.44141-0.44531 0.69141-1.0469 0.69141-1.6758z'
    />
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m480.19 288.39h-241.52c-3.9258 0-7.1055 3.1797-7.1055 7.1016v127.87c0 1.3086 1.0625 2.3672 2.3672 2.3672 1.3086 0 2.3711-1.0586 2.3711-2.3672v-127.87c0-1.3086 1.0586-2.3672 2.3672-2.3672h241.52c1.3086 0 2.3711-1.0586 2.3711-2.3672 0-1.3086-1.0625-2.3672-2.3711-2.3672z'
    />
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m376 416.25c10.676 0 20.918-4.2383 28.465-11.789 7.5508-7.5469 11.789-17.789 11.789-28.465s-4.2383-20.914-11.789-28.461c-7.5469-7.5508-17.789-11.793-28.465-11.793s-20.914 4.2422-28.461 11.793c-7.5508 7.5469-11.793 17.785-11.793 28.461 0.011718 10.672 4.2578 20.906 11.805 28.453s17.777 11.789 28.449 11.801zm0-75.773v0.003906c9.4219 0 18.457 3.7422 25.117 10.402 6.6602 6.6602 10.402 15.695 10.402 25.113 0 9.4219-3.7422 18.457-10.402 25.117-6.6602 6.6602-15.695 10.402-25.117 10.402-9.418 0-18.453-3.7422-25.113-10.402-6.6602-6.6602-10.402-15.695-10.402-25.117 0.007813-9.4141 3.7539-18.445 10.414-25.102 6.6562-6.6602 15.688-10.406 25.102-10.414z'
    />
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m370.08 378.31v13.086h-4.7344c-1.3086 0-2.3711 1.0586-2.3711 2.3672 0 1.3086 1.0625 2.3672 2.3711 2.3672h4.7344v4.7344c0 1.3086 1.0586 2.3672 2.3672 2.3672 1.3086 0 2.3672-1.0586 2.3672-2.3672v-4.7344h2.3672v4.7344c0 1.3086 1.0625 2.3672 2.3711 2.3672 1.3047 0 2.3672-1.0586 2.3672-2.3672v-4.7969c5.957-0.30078 10.637-5.2188 10.637-11.184 0-5.9688-4.6797-10.887-10.637-11.188v-13.086h4.7344c1.3086 0 2.3672-1.0586 2.3672-2.3672 0-1.3086-1.0586-2.3672-2.3672-2.3672h-4.7344v-4.7383c0-1.3047-1.0625-2.3672-2.3672-2.3672-1.3086 0-2.3711 1.0625-2.3711 2.3672v4.7344h-2.3672v-4.7344c0-1.3047-1.0586-2.3672-2.3672-2.3672-1.3086 0-2.3672 1.0625-2.3672 2.3672v4.8008c-5.9609 0.29688-10.637 5.2188-10.637 11.184 0 5.9688 4.6758 10.887 10.637 11.188zm4.7344 13.086v-13.023h2.3672v13.023zm13.023-6.5117c-0.007813 3.3555-2.5742 6.1523-5.918 6.4492v-12.902c3.3438 0.29687 5.9102 3.0938 5.918 6.4531zm-10.656-24.273v13.023h-2.3672v-13.023zm-7.1055 0.0625v12.898h0.003906c-3.3477-0.28516-5.9219-3.0859-5.9219-6.4492 0-3.3594 2.5742-6.1641 5.9219-6.4492z'
    />
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m281.29 316.8h-18.941c-1.3086 0-2.3711 1.0586-2.3711 2.3672v18.941c0 0.62891 0.25 1.2305 0.69531 1.6758 0.44531 0.44531 1.0469 0.69531 1.6758 0.69531 5.6484-0.007813 11.066-2.2539 15.062-6.25 3.9922-3.9961 6.2422-9.4141 6.2461-15.062 0-0.62891-0.25-1.2305-0.69141-1.6758-0.44531-0.44141-1.0469-0.69141-1.6758-0.69141zm-16.574 18.773v-14.039h14.035c-0.51172 3.543-2.1523 6.8242-4.6836 9.3516-2.5312 2.5312-5.8125 4.1758-9.3516 4.6875z'
    />
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m262.34 411.52c-1.3086 0-2.3711 1.0625-2.3711 2.3672v18.945c0 0.62891 0.25 1.2305 0.69531 1.6719 0.44531 0.44531 1.0469 0.69531 1.6758 0.69531h18.941c0.62891 0 1.2305-0.25 1.6758-0.69531 0.44141-0.44141 0.69141-1.043 0.69141-1.6719-0.003906-5.6523-2.2539-11.066-6.2461-15.062-3.9961-3.9961-9.4141-6.2422-15.062-6.25zm2.3672 18.941v-14.035c3.5391 0.51172 6.8203 2.1562 9.3516 4.6836 2.5312 2.5312 4.1719 5.8125 4.6836 9.3555z'
    />
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m489.66 340.48c0.62891 0 1.2305-0.25 1.6758-0.69531 0.44141-0.44531 0.69141-1.0469 0.69141-1.6758v-18.941c0-0.62891-0.25-1.2305-0.69141-1.6758-0.44531-0.44141-1.0469-0.69141-1.6758-0.69141h-18.941c-1.3086 0-2.3672 1.0586-2.3672 2.3672 0.003907 5.6484 2.25 11.066 6.2461 15.062s9.4141 6.2422 15.062 6.25zm-2.3672-18.941v14.035c-3.543-0.51172-6.8203-2.1562-9.3516-4.6875-2.5312-2.5273-4.1758-5.8086-4.6836-9.3516z'
    />
    <SvgPath
      className='primary fill'
      css={{
        fill: theme.colors.gray800,
        transition: theme.transitions.iconColor,
      }}
      d='m470.72 435.2h18.941c0.62891 0 1.2305-0.25 1.6758-0.69531 0.44141-0.44141 0.69141-1.043 0.69141-1.6719v-18.945c0-0.625-0.25-1.2305-0.69141-1.6719-0.44531-0.44531-1.0469-0.69531-1.6758-0.69531-5.6484 0.007813-11.066 2.2539-15.062 6.25-3.9961 3.9961-6.2422 9.4102-6.2461 15.062 0 0.62891 0.24609 1.2305 0.69141 1.6719 0.44531 0.44531 1.0469 0.69531 1.6758 0.69531zm16.574-18.773v14.039h-14.035c0.50781-3.543 2.1523-6.8242 4.6836-9.3555 2.5312-2.5273 5.8086-4.1719 9.3516-4.6836z'
    />
  </Svg>
)
